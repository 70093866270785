export const SS_CATEGORIES = [
  { value: "flats", label: "Flats" },
  { value: "homes-summer-residences", label: "Houses, cottages" },
  { value: "offices", label: "Offices" },
];

export const LOCATIONS = [
  {
    value: "riga",
    label: "Riga",
    districts: [
      {
        value: "centre",
        label: "Centre",
        streets: [
          { value: "11. novembra bund", label: "11. novembra bund" },
          { value: "13. janvara", label: "13. janvara" },
          { value: "Abrenes", label: "Abrenes" },
          { value: "Adminu", label: "Adminu" },
          { value: "Akademijas pl.", label: "Akademijas pl." },
          { value: "Akas", label: "Akas" },
          { value: "Akla", label: "Akla" },
          { value: "Alauksta", label: "Alauksta" },
          { value: "Alberta", label: "Alberta" },
          { value: "Alberta pl.", label: "Alberta pl." },
          { value: "Aldaru", label: "Aldaru" },
          { value: "Alksnaja", label: "Alksnaja" },
          { value: "Alojas", label: "Alojas" },
          { value: "Aluksnes", label: "Aluksnes" },
          { value: "Alunana", label: "Alunana" },
          { value: "Amatu", label: "Amatu" },
          { value: "Andrejostas", label: "Andrejostas" },
          { value: "Anglikanu", label: "Anglikanu" },
          { value: "Annas", label: "Annas" },
          { value: "Antonijas", label: "Antonijas" },
          { value: "Arhitektu", label: "Arhitektu" },
          { value: "Arsenala", label: "Arsenala" },
          { value: "Artilerijas", label: "Artilerijas" },
          { value: "Asara", label: "Asara" },
          { value: "Aspazijas boul.", label: "Aspazijas boul." },
          { value: "Ata", label: "Ata" },
          { value: "Atgriezhu", label: "Atgriezhu" },
          { value: "Audeju", label: "Audeju" },
          { value: "Augshiela", label: "Augshiela" },
          { value: "Ausekla", label: "Ausekla" },
          { value: "Avotu", label: "Avotu" },
          { value: "Barona", label: "Barona" },
          { value: "Baumana sq.", label: "Baumana sq." },
          { value: "Baznicas", label: "Baznicas" },
          { value: "Birznieka-Upisha", label: "Birznieka-Upisha" },
          { value: "Biskapa gate", label: "Biskapa gate" },
          { value: "Blaumana", label: "Blaumana" },
          { value: "Briana", label: "Briana" },
          { value: "Brivibas", label: "Brivibas" },
          { value: "Bruninieku", label: "Bruninieku" },
          { value: "Bukultu", label: "Bukultu" },
          { value: "Centraltirgus", label: "Centraltirgus" },
          { value: "Cesu", label: "Cesu" },
          { value: "Chaka", label: "Chaka" },
          { value: "Citadeles", label: "Citadeles" },
          { value: "Dagdas", label: "Dagdas" },
          { value: "Dainas", label: "Dainas" },
          { value: "Dambja", label: "Dambja" },
          { value: "Darzauglu", label: "Darzauglu" },
          { value: "Daugavas", label: "Daugavas" },
          { value: "Deglava", label: "Deglava" },
          { value: "Doma pl.", label: "Doma pl." },
          { value: "Duntes", label: "Duntes" },
          { value: "Dzirnavu", label: "Dzirnavu" },
          { value: "Eksporta", label: "Eksporta" },
          { value: "Elijas", label: "Elijas" },
          { value: "Elizabetes", label: "Elizabetes" },
          { value: "Elku", label: "Elku" },
          { value: "Erglu", label: "Erglu" },
          { value: "Etnas", label: "Etnas" },
          { value: "Eveles", label: "Eveles" },
          { value: "Gaizina", label: "Gaizina" },
          { value: "Ganibu d.", label: "Ganibu d." },
          { value: "Ganu", label: "Ganu" },
          { value: "Gertrudes", label: "Gertrudes" },
          { value: "Gleznotaju", label: "Gleznotaju" },
          { value: "Gogola", label: "Gogola" },
          { value: "Grecinieku", label: "Grecinieku" },
          { value: "Grostonas", label: "Grostonas" },
          { value: "Hanzas", label: "Hanzas" },
          { value: "Herdera pl.", label: "Herdera pl." },
          { value: "Hospitalu", label: "Hospitalu" },
          { value: "Ierednu", label: "Ierednu" },
          { value: "Ierochu", label: "Ierochu" },
          { value: "Indranu", label: "Indranu" },
          { value: "Invalidu", label: "Invalidu" },
          { value: "Inzhenieru", label: "Inzhenieru" },
          { value: "J. Dalina", label: "J. Dalina" },
          { value: "J. Dikmaņa", label: "J. Dikmaņa" },
          { value: "Jana", label: "Jana" },
          { value: "Jana seta", label: "Jana seta" },
          { value: "Jauniela", label: "Jauniela" },
          { value: "Jekaba", label: "Jekaba" },
          { value: "Jekaba pl.", label: "Jekaba pl." },
          { value: "Jeruzalemes", label: "Jeruzalemes" },
          { value: "Jezusbaznicas", label: "Jezusbaznicas" },
          { value: "Kaleju", label: "Kaleju" },
          { value: "Kalku", label: "Kalku" },
          { value: "Kalnina", label: "Kalnina" },
          { value: "Kalpaka boul.", label: "Kalpaka boul." },
          { value: "Kareivju", label: "Kareivju" },
          { value: "Kaspara", label: "Kaspara" },
          { value: "Katrinas", label: "Katrinas" },
          { value: "Katrinas d.", label: "Katrinas d." },
          { value: "Kazarmu", label: "Kazarmu" },
          { value: "Keninu", label: "Keninu" },
          { value: "Klijanu", label: "Klijanu" },
          { value: "Klostera", label: "Klostera" },
          { value: "Kluca", label: "Kluca" },
          { value: "Klusa", label: "Klusa" },
          { value: "Konventa seta", label: "Konventa seta" },
          { value: "Kramu", label: "Kramu" },
          { value: "Krasotaju", label: "Krasotaju" },
          { value: "Krasta", label: "Krasta" },
          { value: "Kronvalda boul.", label: "Kronvalda boul." },
          { value: "Kungu", label: "Kungu" },
          { value: "Kurbada", label: "Kurbada" },
          { value: "Laboratorijas", label: "Laboratorijas" },
          { value: "Lachplesha", label: "Lachplesha" },
          { value: "Lachu", label: "Lachu" },
          { value: "Laipu", label: "Laipu" },
          { value: "Lakstigalu", label: "Lakstigalu" },
          { value: "Laktas", label: "Laktas" },
          { value: "Lauku", label: "Lauku" },
          { value: "Lejas", label: "Lejas" },
          { value: "Lenсu", label: "Lenсu" },
          { value: "Lielgabalu", label: "Lielgabalu" },
          { value: "Lienes", label: "Lienes" },
          { value: "Livu pl.", label: "Livu pl." },
          { value: "Lugazhu", label: "Lugazhu" },
          { value: "M. Aluksnes", label: "M. Aluksnes" },
          { value: "M. Jaunavu", label: "M. Jaunavu" },
          { value: "M. Jauniela", label: "M. Jauniela" },
          { value: "M. Klijanu", label: "M. Klijanu" },
          { value: "M. Krasta", label: "M. Krasta" },
          { value: "M. Matisa", label: "M. Matisa" },
          { value: "M. Miesnieku", label: "M. Miesnieku" },
          { value: "M. Monetu", label: "M. Monetu" },
          { value: "M. Muzeja", label: "M. Muzeja" },
          { value: "M. Peitavas", label: "M. Peitavas" },
          { value: "M. Piena", label: "M. Piena" },
          { value: "M. Pils", label: "M. Pils" },
          { value: "M. Skolas", label: "M. Skolas" },
          { value: "M. Smilshu", label: "M. Smilshu" },
          { value: "M. Trokshnu", label: "M. Trokshnu" },
          { value: "Maisa", label: "Maisa" },
          { value: "Maiznicas", label: "Maiznicas" },
          { value: "Malpils", label: "Malpils" },
          { value: "Marijas", label: "Marijas" },
          { value: "Marstalu", label: "Marstalu" },
          { value: "Martas", label: "Martas" },
          { value: "Maskavas", label: "Maskavas" },
          { value: "Mastu", label: "Mastu" },
          { value: "Matisa", label: "Matisa" },
          { value: "Mednieku", label: "Mednieku" },
          { value: "Meierovics boul.", label: "Meierovics boul." },
          { value: "Meistaru", label: "Meistaru" },
          { value: "Melngaila", label: "Melngaila" },
          { value: "Meness", label: "Meness" },
          { value: "Merkela", label: "Merkela" },
          { value: "Miera", label: "Miera" },
          { value: "Miesnieku", label: "Miesnieku" },
          { value: "Mikela", label: "Mikela" },
          { value: "Minsterejas", label: "Minsterejas" },
          { value: "Mucenieku", label: "Mucenieku" },
          { value: "Muitas", label: "Muitas" },
          { value: "Muku", label: "Muku" },
          { value: "Murnieku", label: "Murnieku" },
          { value: "Narvas", label: "Narvas" },
          { value: "Negu", label: "Negu" },
          { value: "Nitaures", label: "Nitaures" },
          { value: "Noliktavas", label: "Noliktavas" },
          { value: "Ozolu", label: "Ozolu" },
          { value: "Palasta", label: "Palasta" },
          { value: "Palidzibas", label: "Palidzibas" },
          { value: "Pasta", label: "Pasta" },
          { value: "Patversmes", label: "Patversmes" },
          { value: "Pededzes", label: "Pededzes" },
          { value: "Peitavas", label: "Peitavas" },
          { value: "Peldu", label: "Peldu" },
          { value: "Pernavas", label: "Pernavas" },
          { value: "Perses", label: "Perses" },
          { value: "Peterbaznicas", label: "Peterbaznicas" },
          { value: "Petersalas", label: "Petersalas" },
          { value: "Piena", label: "Piena" },
          { value: "Pils", label: "Pils" },
          { value: "Pils pl.", label: "Pils pl." },
          { value: "Plavas", label: "Plavas" },
          { value: "Polu gate", label: "Polu gate" },
          { value: "Pragas", label: "Pragas" },
          { value: "Pulkv. Briezha", label: "Pulkv. Briezha" },
          { value: "Pumpura", label: "Pumpura" },
          { value: "Pupolu", label: "Pupolu" },
          { value: "Pushkina", label: "Pushkina" },
          { value: "Radio", label: "Radio" },
          { value: "Raina boul.", label: "Raina boul." },
          { value: "Ramulu", label: "Ramulu" },
          { value: "Ratslaukums", label: "Ratslaukums" },
          { value: "Reimersa", label: "Reimersa" },
          { value: "Republikas pl.", label: "Republikas pl." },
          { value: "Reriha", label: "Reriha" },
          { value: "Reznas", label: "Reznas" },
          { value: "Ridzenes", label: "Ridzenes" },
          { value: "Riepnieku", label: "Riepnieku" },
          { value: "Rozena", label: "Rozena" },
          { value: "Rudolfa", label: "Rudolfa" },
          { value: "Rujienas", label: "Rujienas" },
          { value: "Rupniecibas", label: "Rupniecibas" },
          { value: "Rusina", label: "Rusina" },
          { value: "Sadovnikova", label: "Sadovnikova" },
          { value: "Sakaru", label: "Sakaru" },
          { value: "Sapieru", label: "Sapieru" },
          { value: "Saremas", label: "Saremas" },
          { value: "Satekles", label: "Satekles" },
          { value: "Senchu", label: "Senchu" },
          { value: "Sermulinu", label: "Sermulinu" },
          { value: "Sharlotes", label: "Sharlotes" },
          { value: "Shkunu", label: "Shkunu" },
          { value: "Silmachu", label: "Silmachu" },
          { value: "Skanstes", label: "Skanstes" },
          { value: "Skarnu", label: "Skarnu" },
          { value: "Skolas", label: "Skolas" },
          { value: "Skrindu", label: "Skrindu" },
          { value: "Smilshu", label: "Smilshu" },
          { value: "Spargelu", label: "Spargelu" },
          { value: "Spikeru", label: "Spikeru" },
          { value: "Sporta", label: "Sporta" },
          { value: "Stabu", label: "Stabu" },
          { value: "Stacijas pl.", label: "Stacijas pl." },
          { value: "Stadiona", label: "Stadiona" },
          { value: "Stralnieku pl.", label: "Stralnieku pl." },
          { value: "Straumes", label: "Straumes" },
          { value: "Strelnieku", label: "Strelnieku" },
          { value: "Strenchu", label: "Strenchu" },
          { value: "Strugu", label: "Strugu" },
          { value: "Suntazhu", label: "Suntazhu" },
          { value: "Svaru", label: "Svaru" },
          { value: "Tala", label: "Tala" },
          { value: "Tallinas", label: "Tallinas" },
          { value: "Teatra", label: "Teatra" },
          { value: "Terbatas", label: "Terbatas" },
          { value: "Terezes", label: "Terezes" },
          { value: "Timoteja", label: "Timoteja" },
          { value: "Tirgonu", label: "Tirgonu" },
          { value: "Tomsona", label: "Tomsona" },
          { value: "Torna", label: "Torna" },
          { value: "Trokshnu", label: "Trokshnu" },
          { value: "Turgeneva", label: "Turgeneva" },
          { value: "Udensvada", label: "Udensvada" },
          { value: "Upes", label: "Upes" },
          { value: "Uriekstes", label: "Uriekstes" },
          { value: "Vagnera", label: "Vagnera" },
          { value: "Vagonu", label: "Vagonu" },
          { value: "Valdemara", label: "Valdemara" },
          { value: "Valkas", label: "Valkas" },
          { value: "Valmieras", label: "Valmieras" },
          { value: "Valnu", label: "Valnu" },
          { value: "Varnu", label: "Varnu" },
          { value: "Vashingtona pl.", label: "Vashingtona pl." },
          { value: "Vecpilsetas", label: "Vecpilsetas" },
          { value: "Veru", label: "Veru" },
          { value: "Vesetas", label: "Vesetas" },
          { value: "Vestienas", label: "Vestienas" },
          { value: "Vezhu", label: "Vezhu" },
          { value: "Vidus", label: "Vidus" },
          { value: "Vietalvas", label: "Vietalvas" },
          { value: "Vilandes", label: "Vilandes" },
          { value: "Vingrotaju", label: "Vingrotaju" },
          { value: "Visvalzha", label: "Visvalzha" },
          { value: "Zaku", label: "Zaku" },
          { value: "Zala", label: "Zala" },
          { value: "Zaubes", label: "Zaubes" },
          { value: "Zemitana", label: "Zemitana" },
          { value: "Zirgu", label: "Zirgu" },
          { value: "Zirnu", label: "Zirnu" },
          { value: "Zvaigzhnu", label: "Zvaigzhnu" },
        ],
      },
      {
        value: "agenskalns",
        label: "Agenskalns",
        streets: [
          { value: "Agenskalna", label: "Agenskalna" },
          { value: "Akachu", label: "Akachu" },
          { value: "Akmenu", label: "Akmenu" },
          { value: "Alises", label: "Alises" },
          { value: "Altonavas", label: "Altonavas" },
          { value: "Amalijas", label: "Amalijas" },
          { value: "Astras", label: "Astras" },
          { value: "Atputas", label: "Atputas" },
          { value: "Auces", label: "Auces" },
          { value: "Auglu", label: "Auglu" },
          { value: "Balasta d.", label: "Balasta d." },
          { value: "Baldones", label: "Baldones" },
          { value: "Balozhu", label: "Balozhu" },
          { value: "Barinu", label: "Barinu" },
          { value: "Bartas", label: "Bartas" },
          { value: "Bauskas", label: "Bauskas" },
          { value: "Bezdeligu", label: "Bezdeligu" },
          { value: "Biekensalas", label: "Biekensalas" },
          { value: "Bishu", label: "Bishu" },
          { value: "Brivzemnieka", label: "Brivzemnieka" },
          { value: "Burshu", label: "Burshu" },
          { value: "Buru", label: "Buru" },
          { value: "Cieceres", label: "Cieceres" },
          { value: "Cigorinu", label: "Cigorinu" },
          { value: "Darba", label: "Darba" },
          { value: "Daugavgrivas", label: "Daugavgrivas" },
          { value: "Dobeles", label: "Dobeles" },
          { value: "Dreilinu", label: "Dreilinu" },
          { value: "Dzirciema", label: "Dzirciema" },
          { value: "Elviras", label: "Elviras" },
          { value: "Ergelu", label: "Ergelu" },
          { value: "Ernestines", label: "Ernestines" },
          { value: "Gardenes", label: "Gardenes" },
          { value: "Gregora", label: "Gregora" },
          { value: "Griezes", label: "Griezes" },
          { value: "Grina boul.", label: "Grina boul." },
          { value: "Grobinas", label: "Grobinas" },
          { value: "Gumijas", label: "Gumijas" },
          { value: "H. Celmiņa", label: "H. Celmiņa" },
          { value: "Hamana", label: "Hamana" },
          { value: "Hermana", label: "Hermana" },
          { value: "Jaunmoku", label: "Jaunmoku" },
          { value: "Jaunpils", label: "Jaunpils" },
          { value: "Jelgavas", label: "Jelgavas" },
          { value: "Jurmalas g.", label: "Jurmalas g." },
          { value: "Kaktu", label: "Kaktu" },
          { value: "Kalnciema", label: "Kalnciema" },
          { value: "Kandavas", label: "Kandavas" },
          { value: "Kapselu", label: "Kapselu" },
          { value: "Kauguru", label: "Kauguru" },
          { value: "Kivulu", label: "Kivulu" },
          { value: "Klingeru", label: "Klingeru" },
          { value: "Kliveru", label: "Kliveru" },
          { value: "Kokles", label: "Kokles" },
          { value: "Kolkasraga", label: "Kolkasraga" },
          { value: "Konrada", label: "Konrada" },
          { value: "Kreslas", label: "Kreslas" },
          { value: "Kristapa", label: "Kristapa" },
          { value: "Krogus", label: "Krogus" },
          { value: "Kruzes", label: "Kruzes" },
          { value: "Kudras", label: "Kudras" },
          { value: "Kugu", label: "Kugu" },
          { value: "Kuldigas", label: "Kuldigas" },
          { value: "Kurpnieku", label: "Kurpnieku" },
          { value: "Laivu", label: "Laivu" },
          { value: "Lapu", label: "Lapu" },
          { value: "Lavizes", label: "Lavizes" },
          { value: "Lermontova", label: "Lermontova" },
          { value: "Lielirbes", label: "Lielirbes" },
          { value: "Liepajas", label: "Liepajas" },
          { value: "Ludvika", label: "Ludvika" },
          { value: "M. Bezdeligu", label: "M. Bezdeligu" },
          { value: "M. Bishu", label: "M. Bishu" },
          { value: "M. Cepla", label: "M. Cepla" },
          { value: "M. Kuldigas", label: "M. Kuldigas" },
          { value: "M. Nometnu", label: "M. Nometnu" },
          { value: "Magonu", label: "Magonu" },
          { value: "Maiznieku", label: "Maiznieku" },
          { value: "Maras al.", label: "Maras al." },
          { value: "Margrietas", label: "Margrietas" },
          { value: "Martina", label: "Martina" },
          { value: "Marupes", label: "Marupes" },
          { value: "Mazirbes", label: "Mazirbes" },
          { value: "Medus", label: "Medus" },
          { value: "Melnsila", label: "Melnsila" },
          { value: "Mersraga", label: "Mersraga" },
          { value: "Meteora", label: "Meteora" },
          { value: "Mezha", label: "Mezha" },
          { value: "Mukusalas", label: "Mukusalas" },
          { value: "Naras", label: "Naras" },
          { value: "Nomales", label: "Nomales" },
          { value: "Nometnu", label: "Nometnu" },
          { value: "O. Vaciesha", label: "O. Vaciesha" },
          { value: "Olgas", label: "Olgas" },
          { value: "Ormanu", label: "Ormanu" },
          { value: "Ozolnieku", label: "Ozolnieku" },
          { value: "Paltmales", label: "Paltmales" },
          { value: "Parslas", label: "Parslas" },
          { value: "Pilsonu", label: "Pilsonu" },
          { value: "Plesuma", label: "Plesuma" },
          { value: "Puku", label: "Puku" },
          { value: "Ranka d.", label: "Ranka d." },
          { value: "Remtes", label: "Remtes" },
          { value: "Rododendru", label: "Rododendru" },
          { value: "Rudbarzhu", label: "Rudbarzhu" },
          { value: "Sabiles", label: "Sabiles" },
          { value: "Satiksmes", label: "Satiksmes" },
          { value: "Saules al.", label: "Saules al." },
          { value: "Setas", label: "Setas" },
          { value: "Skaistkalnes", label: "Skaistkalnes" },
          { value: "Slokas", label: "Slokas" },
          { value: "Smilga", label: "Smilga" },
          { value: "Spares", label: "Spares" },
          { value: "Stava", label: "Stava" },
          { value: "Strazdu", label: "Strazdu" },
          { value: "Struteles", label: "Struteles" },
          { value: "Talsu", label: "Talsu" },
          { value: "Tapeshu", label: "Tapeshu" },
          { value: "Templa", label: "Templa" },
          { value: "Tirgus", label: "Tirgus" },
          { value: "Tornakalna", label: "Tornakalna" },
          { value: "Trijadibas", label: "Trijadibas" },
          { value: "Tukuma", label: "Tukuma" },
          { value: "Ulmana g.", label: "Ulmana g." },
          { value: "Uzvaras boul.", label: "Uzvaras boul." },
          { value: "Valdemara", label: "Valdemara" },
          { value: "Valentina", label: "Valentina" },
          { value: "Valguma", label: "Valguma" },
          { value: "Vasaras", label: "Vasaras" },
          { value: "Veca Jurmalas g.", label: "Veca Jurmalas g." },
          { value: "Veja", label: "Veja" },
          { value: "Ventas", label: "Ventas" },
          { value: "Ventspils", label: "Ventspils" },
          { value: "Vesera", label: "Vesera" },
          { value: "Vienibas g.", label: "Vienibas g." },
          { value: "Vilipa", label: "Vilipa" },
          { value: "Vilku", label: "Vilku" },
          { value: "Zellu", label: "Zellu" },
          { value: "Zemaishu", label: "Zemaishu" },
          { value: "Zhubishu", label: "Zhubishu" },
          { value: "Zvanu", label: "Zvanu" },
        ],
      },
      {
        value: "aplokciems",
        label: "Aplokciems",
        streets: [
          { value: "Aplokciema", label: "Aplokciema" },
          { value: "Briezhu", label: "Briezhu" },
          { value: "Chiekuru", label: "Chiekuru" },
          { value: "Ezera", label: "Ezera" },
          { value: "Jaunciema g.", label: "Jaunciema g." },
          { value: "Krapes", label: "Krapes" },
          { value: "Lades", label: "Lades" },
          { value: "Ledurgas", label: "Ledurgas" },
          { value: "Mezhnoras", label: "Mezhnoras" },
          { value: "Milgravja", label: "Milgravja" },
          { value: "Mores", label: "Mores" },
          { value: "Ostas pr.", label: "Ostas pr." },
          { value: "Viestura pr.", label: "Viestura pr." },
          { value: "Zushu", label: "Zushu" },
        ],
      },
      {
        value: "beberbeki",
        label: "Beberbeki",
        streets: [
          { value: "Babites", label: "Babites" },
          { value: "Beberbeku", label: "Beberbeku" },
          { value: "Beberbeku 5. l.", label: "Beberbeku 5. l." },
          { value: "Beberbeku 6. l.", label: "Beberbeku 6. l." },
          { value: "Beberbeku 7. l.", label: "Beberbeku 7. l." },
          { value: "Beberbeku 9. l.", label: "Beberbeku 9. l." },
          { value: "Chuguna", label: "Chuguna" },
          { value: "Ciema", label: "Ciema" },
          { value: "Gaviezes", label: "Gaviezes" },
          { value: "Krotes", label: "Krotes" },
          { value: "Nigrandes", label: "Nigrandes" },
          { value: "Purmsatu", label: "Purmsatu" },
          { value: "Satinu", label: "Satinu" },
          { value: "Turlavas", label: "Turlavas" },
          { value: "Ulmana g.", label: "Ulmana g." },
          { value: "Virgas", label: "Virgas" },
        ],
      },
      {
        value: "bergi",
        label: "Bergi",
        streets: [
          { value: "Acaliju", label: "Acaliju" },
          { value: "Andreja", label: "Andreja" },
          { value: "Augstienes", label: "Augstienes" },
          { value: "Baibas", label: "Baibas" },
          { value: "Balozu", label: "Balozu" },
          { value: "Banutas", label: "Banutas" },
          { value: "Bergu", label: "Bergu" },
          { value: "Bitenu", label: "Bitenu" },
          { value: "Brivdabas", label: "Brivdabas" },
          { value: "Brivibas g.", label: "Brivibas g." },
          { value: "Cinitaju", label: "Cinitaju" },
          { value: "Ezera pr.", label: "Ezera pr." },
          { value: "Gatina", label: "Gatina" },
          { value: "Jaunciema g.", label: "Jaunciema g." },
          { value: "Jaunibas", label: "Jaunibas" },
          { value: "Jumiku", label: "Jumiku" },
          { value: "Kaktusu", label: "Kaktusu" },
          { value: "Kraujas", label: "Kraujas" },
          { value: "Laimas", label: "Laimas" },
          { value: "Lauvu", label: "Lauvu" },
          { value: "Lejasciema", label: "Lejasciema" },
          { value: "Lides", label: "Lides" },
          { value: "Ligo", label: "Ligo" },
          { value: "Lusu", label: "Lusu" },
          { value: "Madaru", label: "Madaru" },
          { value: "Makonu", label: "Makonu" },
          { value: "Maltuves", label: "Maltuves" },
          { value: "Melderu", label: "Melderu" },
          { value: "Parka", label: "Parka" },
          { value: "Purva sq.", label: "Purva sq." },
          { value: "Rozhu", label: "Rozhu" },
          { value: "Saulcerites", label: "Saulcerites" },
          { value: "Sika", label: "Sika" },
          { value: "Skuju sq.", label: "Skuju sq." },
          { value: "Suitu", label: "Suitu" },
          { value: "Tadenavas", label: "Tadenavas" },
          { value: "Uguns", label: "Uguns" },
          { value: "Ulzha", label: "Ulzha" },
          { value: "Upesciema", label: "Upesciema" },
          { value: "Vejdzirnavu", label: "Vejdzirnavu" },
          { value: "Zalisu", label: "Zalisu" },
          { value: "Zalumu", label: "Zalumu" },
          { value: "Zanes", label: "Zanes" },
        ],
      },
      {
        value: "biekensala",
        label: "Biekensala",
        streets: [
          { value: "Akachu", label: "Akachu" },
          { value: "Akmenu", label: "Akmenu" },
          { value: "Bauskas", label: "Bauskas" },
          { value: "Biekensalas", label: "Biekensalas" },
          { value: "Buru", label: "Buru" },
          { value: "H. Celmiņa", label: "H. Celmiņa" },
          { value: "Jelgavas", label: "Jelgavas" },
          { value: "Kliveru", label: "Kliveru" },
          { value: "Konrada", label: "Konrada" },
          { value: "Kugu", label: "Kugu" },
          { value: "Laivu", label: "Laivu" },
          { value: "Ludvika", label: "Ludvika" },
          { value: "M. Cepla", label: "M. Cepla" },
          { value: "Mukusalas", label: "Mukusalas" },
          { value: "Ranka d.", label: "Ranka d." },
          { value: "Satiksmes", label: "Satiksmes" },
          { value: "Skaistkalnes", label: "Skaistkalnes" },
          { value: "Stava", label: "Stava" },
          { value: "Trijadibas", label: "Trijadibas" },
          { value: "Uzvaras boul.", label: "Uzvaras boul." },
          { value: "Valguma", label: "Valguma" },
          { value: "Veja", label: "Veja" },
          { value: "Vienibas g.", label: "Vienibas g." },
        ],
      },
      {
        value: "bierini",
        label: "Bierini",
        streets: [
          { value: "Abavas", label: "Abavas" },
          { value: "Aizupes", label: "Aizupes" },
          { value: "Alsungas", label: "Alsungas" },
          { value: "Altonavas", label: "Altonavas" },
          { value: "Amulas", label: "Amulas" },
          { value: "Apguldes", label: "Apguldes" },
          { value: "Apriku", label: "Apriku" },
          { value: "Arlavas", label: "Arlavas" },
          { value: "Asites", label: "Asites" },
          { value: "Aurenieku", label: "Aurenieku" },
          { value: "Bramberges", label: "Bramberges" },
          { value: "Brigades", label: "Brigades" },
          { value: "Bruklenu", label: "Bruklenu" },
          { value: "Ceres", label: "Ceres" },
          { value: "Cirulu", label: "Cirulu" },
          { value: "Codes", label: "Codes" },
          { value: "Daibes", label: "Daibes" },
          { value: "Daliju", label: "Daliju" },
          { value: "Daugulu", label: "Daugulu" },
          { value: "Diklu", label: "Diklu" },
          { value: "Drustu", label: "Drustu" },
          { value: "Druvas", label: "Druvas" },
          { value: "Dzelzcela", label: "Dzelzcela" },
          { value: "Elejas", label: "Elejas" },
          { value: "Engures", label: "Engures" },
          { value: "Gimnastikas", label: "Gimnastikas" },
          { value: "Graudu", label: "Graudu" },
          { value: "Grobinas", label: "Grobinas" },
          { value: "Gulbenes", label: "Gulbenes" },
          { value: "Imulas", label: "Imulas" },
          { value: "Ivandes", label: "Ivandes" },
          { value: "Ives", label: "Ives" },
          { value: "Kabiles", label: "Kabiles" },
          { value: "Kalvenes", label: "Kalvenes" },
          { value: "Kantora", label: "Kantora" },
          { value: "Kravas", label: "Kravas" },
          { value: "Kurmales", label: "Kurmales" },
          { value: "Kurmenes", label: "Kurmenes" },
          { value: "Kursishu", label: "Kursishu" },
          { value: "Laidzes", label: "Laidzes" },
          { value: "Lestenes", label: "Lestenes" },
          { value: "Libagu", label: "Libagu" },
          { value: "Lidaku", label: "Lidaku" },
          { value: "Lidera", label: "Lidera" },
          { value: "Liepajas", label: "Liepajas" },
          { value: "Lives", label: "Lives" },
          { value: "Lutrinu", label: "Lutrinu" },
          { value: "Maksha", label: "Maksha" },
          { value: "Memeles", label: "Memeles" },
          { value: "Mezhotnes", label: "Mezhotnes" },
          { value: "O. Vaciesha", label: "O. Vaciesha" },
          { value: "Ozolnieku", label: "Ozolnieku" },
          { value: "Pampalu", label: "Pampalu" },
          { value: "Penkules", label: "Penkules" },
          { value: "Planicas", label: "Planicas" },
          { value: "Posma", label: "Posma" },
          { value: "Raudas", label: "Raudas" },
          { value: "Robezhu", label: "Robezhu" },
          { value: "Sartes", label: "Sartes" },
          { value: "Sejas", label: "Sejas" },
          { value: "Sipeles", label: "Sipeles" },
          { value: "Slampes", label: "Slampes" },
          { value: "Spares", label: "Spares" },
          { value: "Spirgus", label: "Spirgus" },
          { value: "Spoles", label: "Spoles" },
          { value: "Terinu", label: "Terinu" },
          { value: "Tirela", label: "Tirela" },
          { value: "Ugales", label: "Ugales" },
          { value: "Ulmana g.", label: "Ulmana g." },
          { value: "Upesgrivas", label: "Upesgrivas" },
          { value: "Vecauces", label: "Vecauces" },
          { value: "Vecmoku", label: "Vecmoku" },
          { value: "Vecsaules", label: "Vecsaules" },
          { value: "Velenu", label: "Velenu" },
          { value: "Zemites", label: "Zemites" },
          { value: "Zutenu", label: "Zutenu" },
          { value: "Zvardes", label: "Zvardes" },
        ],
      },
      {
        value: "bolderaya",
        label: "Bolderaya",
        streets: [
          { value: "Adama", label: "Adama" },
          { value: "Admiralu", label: "Admiralu" },
          { value: "Apakshgravja", label: "Apakshgravja" },
          { value: "Apakshgravja", label: "Apakshgravja" },
          { value: "Bangu", label: "Bangu" },
          { value: "Bruzhu", label: "Bruzhu" },
          { value: "Bungu", label: "Bungu" },
          { value: "Daugavgrivas h.", label: "Daugavgrivas h." },
          { value: "Dolomita", label: "Dolomita" },
          { value: "Finiera", label: "Finiera" },
          { value: "Gaigalas", label: "Gaigalas" },
          { value: "Galdnieku", label: "Galdnieku" },
          { value: "Gobas", label: "Gobas" },
          { value: "Grundulu", label: "Grundulu" },
          { value: "Gubernciems", label: "Gubernciems" },
          { value: "Gundegas", label: "Gundegas" },
          { value: "Ievas", label: "Ievas" },
          { value: "Jatnieku", label: "Jatnieku" },
          { value: "Jomas", label: "Jomas" },
          { value: "Jurnieku", label: "Jurnieku" },
          { value: "Kanepju", label: "Kanepju" },
          { value: "Kangaru", label: "Kangaru" },
          { value: "Kaplu", label: "Kaplu" },
          { value: "Kapteinu", label: "Kapteinu" },
          { value: "Kastanu", label: "Kastanu" },
          { value: "Kemmdziju", label: "Kemmdziju" },
          { value: "Keramikas", label: "Keramikas" },
          { value: "Kleistu", label: "Kleistu" },
          { value: "Kodola", label: "Kodola" },
          { value: "Kvarca", label: "Kvarca" },
          { value: "Lemeshu", label: "Lemeshu" },
          { value: "Liela", label: "Liela" },
          { value: "Lielupes", label: "Lielupes" },
          { value: "Lignuma", label: "Lignuma" },
          { value: "Mencu", label: "Mencu" },
          { value: "Meniku", label: "Meniku" },
          { value: "Mezhrozishu", label: "Mezhrozishu" },
          { value: "Miglas", label: "Miglas" },
          { value: "Piestatnes", label: "Piestatnes" },
          { value: "Plata", label: "Plata" },
          { value: "Silikatu", label: "Silikatu" },
          { value: "Spailes", label: "Spailes" },
          { value: "Stagaru", label: "Stagaru" },
          { value: "Sturmanu", label: "Sturmanu" },
          { value: "Tekstilnieku", label: "Tekstilnieku" },
          { value: "Velkonu", label: "Velkonu" },
          { value: "Viesnicas", label: "Viesnicas" },
          { value: "Zemkopju", label: "Zemkopju" },
        ],
      },
      {
        value: "breksi",
        label: "Breksi",
        streets: [
          { value: "Abzas", label: "Abzas" },
          { value: "Bebrenes", label: "Bebrenes" },
          { value: "Bikernieku", label: "Bikernieku" },
          { value: "Breksu", label: "Breksu" },
          { value: "Divajas", label: "Divajas" },
          { value: "Ezernieku", label: "Ezernieku" },
          { value: "Ezupes", label: "Ezupes" },
          { value: "Irbupites", label: "Irbupites" },
          { value: "Juglasciema b.", label: "Juglasciema b." },
          { value: "Kalejgravja", label: "Kalejgravja" },
          { value: "Krievupes", label: "Krievupes" },
          { value: "Levenstrauta", label: "Levenstrauta" },
          { value: "Mergupes", label: "Mergupes" },
          { value: "Nautrenu", label: "Nautrenu" },
          { value: "Osupites", label: "Osupites" },
          { value: "Pecorites", label: "Pecorites" },
          { value: "Pietenupes", label: "Pietenupes" },
          { value: "Sidraburgas", label: "Sidraburgas" },
          { value: "Sindes", label: "Sindes" },
          { value: "Sinoles", label: "Sinoles" },
        ],
      },
      {
        value: "bukulti",
        label: "Bukulti",
        streets: [
          { value: "Adazhu", label: "Adazhu" },
          { value: "Amolina", label: "Amolina" },
          { value: "Avotkalnu", label: "Avotkalnu" },
          { value: "Berzkalnes", label: "Berzkalnes" },
          { value: "Eglu", label: "Eglu" },
          { value: "Igates", label: "Igates" },
          { value: "Jaunciema g.", label: "Jaunciema g." },
          { value: "Kanala", label: "Kanala" },
          { value: "Mezhares", label: "Mezhares" },
          { value: "Negu", label: "Negu" },
          { value: "Olu", label: "Olu" },
          { value: "Ozolkalnu", label: "Ozolkalnu" },
          { value: "Priedkalnes", label: "Priedkalnes" },
          { value: "Rozhupes", label: "Rozhupes" },
          { value: "Smilgu", label: "Smilgu" },
          { value: "Vigriezhu", label: "Vigriezhu" },
        ],
      },
      {
        value: "bulli",
        label: "Bulli",
        streets: [
          { value: "Asnu", label: "Asnu" },
          { value: "Dzelmes", label: "Dzelmes" },
          { value: "Dziesmas", label: "Dziesmas" },
          { value: "Dzintara", label: "Dzintara" },
          { value: "Ielejas", label: "Ielejas" },
          { value: "Ilmena", label: "Ilmena" },
          { value: "Oshu", label: "Oshu" },
          { value: "Ritabulli", label: "Ritabulli" },
          { value: "Rojas", label: "Rojas" },
          { value: "Ronu", label: "Ronu" },
          { value: "Vakarbulli", label: "Vakarbulli" },
          { value: "Vetras", label: "Vetras" },
          { value: "Zegelu", label: "Zegelu" },
        ],
      },
      {
        value: "chiekurkalns",
        label: "Chiekurkalns",
        streets: [
          { value: "Abulas", label: "Abulas" },
          { value: "Aizsaules", label: "Aizsaules" },
          { value: "Atlasa", label: "Atlasa" },
          { value: "Atputas al.", label: "Atputas al." },
          { value: "Augstrozes", label: "Augstrozes" },
          { value: "Bergenas", label: "Bergenas" },
          { value: "Bergmana", label: "Bergmana" },
          { value: "Bernatu", label: "Bernatu" },
          { value: "Berzpils", label: "Berzpils" },
          { value: "Chiekurkalna 1. l.", label: "Chiekurkalna 1. l." },
          {
            value: "Chiekurkalna 1. cr. l.",
            label: "Chiekurkalna 1. cr. l.",
          },
          { value: "Chiekurkalna 2. l.", label: "Chiekurkalna 2. l." },
          {
            value: "Chiekurkalna 2. cr. l.",
            label: "Chiekurkalna 2. cr. l.",
          },
          {
            value: "Chiekurkalna 3. cr. l.",
            label: "Chiekurkalna 3. cr. l.",
          },
          {
            value: "Chiekurkalna 4. cr. l.",
            label: "Chiekurkalna 4. cr. l.",
          },
          {
            value: "Chiekurkalna 5. cr. l.",
            label: "Chiekurkalna 5. cr. l.",
          },
          {
            value: "Chiekurkalna 6. cr. l.",
            label: "Chiekurkalna 6. cr. l.",
          },
          {
            value: "Chiekurkalna 7. cr. l.",
            label: "Chiekurkalna 7. cr. l.",
          },
          {
            value: "Chiekurkalna 8. cr. l.",
            label: "Chiekurkalna 8. cr. l.",
          },
          {
            value: "Chiekurkalna 9. cr. l.",
            label: "Chiekurkalna 9. cr. l.",
          },
          { value: "Cimzes", label: "Cimzes" },
          { value: "Darzina", label: "Darzina" },
          { value: "Ezermalas", label: "Ezermalas" },
          { value: "Gatartas", label: "Gatartas" },
          { value: "Gaujas", label: "Gaujas" },
          { value: "Gdanskas", label: "Gdanskas" },
          { value: "Glika", label: "Glika" },
          { value: "Hamburgas", label: "Hamburgas" },
          { value: "Inchukalna", label: "Inchukalna" },
          { value: "Janshevska pr.", label: "Janshevska pr." },
          { value: "Kempes", label: "Kempes" },
          { value: "Kishezera", label: "Kishezera" },
          { value: "Klaipedas", label: "Klaipedas" },
          { value: "Kokneses pr.", label: "Kokneses pr." },
          { value: "Krustabaznicas", label: "Krustabaznicas" },
          { value: "Libekas", label: "Libekas" },
          { value: "Liepupes", label: "Liepupes" },
          { value: "Ligatnes", label: "Ligatnes" },
          { value: "Lizuma", label: "Lizuma" },
          { value: "Mailes", label: "Mailes" },
          { value: "Matera", label: "Matera" },
          { value: "Mezha pr.", label: "Mezha pr." },
          { value: "Mezhnoras", label: "Mezhnoras" },
          { value: "Nesaules", label: "Nesaules" },
          { value: "Olava", label: "Olava" },
          { value: "Ostas pr.", label: "Ostas pr." },
          { value: "Pavu", label: "Pavu" },
          { value: "Peterupes", label: "Peterupes" },
          { value: "Poruka", label: "Poruka" },
          { value: "Rusova", label: "Rusova" },
          { value: "Sakses", label: "Sakses" },
          { value: "Saulkrastu", label: "Saulkrastu" },
          { value: "Shchecinas", label: "Shchecinas" },
          { value: "Siguldas pr.", label: "Siguldas pr." },
          { value: "Skultes", label: "Skultes" },
          { value: "Sprukstes", label: "Sprukstes" },
          { value: "Starta", label: "Starta" },
          { value: "Stendera", label: "Stendera" },
          { value: "Stienes", label: "Stienes" },
          { value: "Stokholmas", label: "Stokholmas" },
          { value: "Sudrabu Edzhus", label: "Sudrabu Edzhus" },
          { value: "Talejas", label: "Talejas" },
          { value: "Teraudlietuves", label: "Teraudlietuves" },
          { value: "Tilta", label: "Tilta" },
          { value: "Trikatas", label: "Trikatas" },
          { value: "Varonu", label: "Varonu" },
          { value: "Vatsona", label: "Vatsona" },
          { value: "Velmes", label: "Velmes" },
          { value: "Viestura pr.", label: "Viestura pr." },
          { value: "Visbijas pr.", label: "Visbijas pr." },
          { value: "Viskalu", label: "Viskalu" },
          { value: "Zemgala gatve", label: "Zemgala gatve" },
        ],
      },
      {
        value: "darzciems",
        label: "Darzciems",
        streets: [
          { value: "Aizvaru", label: "Aizvaru" },
          { value: "Akotu", label: "Akotu" },
          { value: "Apmetnu", label: "Apmetnu" },
          { value: "Barkavas", label: "Barkavas" },
          { value: "Cielavu", label: "Cielavu" },
          { value: "Darzciema", label: "Darzciema" },
          { value: "Deglava", label: "Deglava" },
          { value: "Gaismas", label: "Gaismas" },
          { value: "Grishlu", label: "Grishlu" },
          { value: "Ilukstes", label: "Ilukstes" },
          { value: "Istras", label: "Istras" },
          { value: "Jaunrozes", label: "Jaunrozes" },
          { value: "Jenotu", label: "Jenotu" },
          { value: "Kaibalas", label: "Kaibalas" },
          { value: "Kaku", label: "Kaku" },
          { value: "Kamenu", label: "Kamenu" },
          { value: "Karsavas", label: "Karsavas" },
          { value: "Klanu", label: "Klanu" },
          { value: "Krauklu", label: "Krauklu" },
          { value: "Kupravas", label: "Kupravas" },
          { value: "Lapsu", label: "Lapsu" },
          { value: "Latgales", label: "Latgales" },
          { value: "Laudonas", label: "Laudonas" },
          { value: "Livanu", label: "Livanu" },
          { value: "Lubanas", label: "Lubanas" },
          { value: "Nicgales", label: "Nicgales" },
          { value: "Papardes", label: "Papardes" },
          { value: "Piedrujas", label: "Piedrujas" },
          { value: "Pildas", label: "Pildas" },
          { value: "Plauzhu", label: "Plauzhu" },
          { value: "Preilu", label: "Preilu" },
          { value: "Raicenes", label: "Raicenes" },
          { value: "Rezeknes", label: "Rezeknes" },
          { value: "Rumbulas", label: "Rumbulas" },
          { value: "Sesku", label: "Sesku" },
          { value: "Sitas", label: "Sitas" },
          { value: "Skanu", label: "Skanu" },
          { value: "Skudru", label: "Skudru" },
          { value: "Slavu", label: "Slavu" },
          { value: "Steigas", label: "Steigas" },
          { value: "Stopinu", label: "Stopinu" },
          { value: "Strautu", label: "Strautu" },
          { value: "Trasuna", label: "Trasuna" },
          { value: "Ubeles", label: "Ubeles" },
          { value: "Vestienas", label: "Vestienas" },
          { value: "Vietalvas", label: "Vietalvas" },
          { value: "Viranes", label: "Viranes" },
          { value: "Zebiekstes", label: "Zebiekstes" },
          { value: "Zeltinu", label: "Zeltinu" },
          { value: "Zundфnu", label: "Zundфnu" },
          { value: "Zvartavas", label: "Zvartavas" },
        ],
      },
      {
        value: "darzini",
        label: "Darzini",
        streets: [
          { value: "Abelu", label: "Abelu" },
          { value: "Aizsprosta", label: "Aizsprosta" },
          { value: "Aroniju", label: "Aroniju" },
          { value: "Atvaru", label: "Atvaru" },
          { value: "Cidoniju", label: "Cidoniju" },
          { value: "Darzinu", label: "Darzinu" },
          { value: "Darzinu 1. l.", label: "Darzinu 1. l." },
          { value: "Darzinu 10. l.", label: "Darzinu 10. l." },
          { value: "Darzinu 11. l.", label: "Darzinu 11. l." },
          { value: "Darzinu 12. l.", label: "Darzinu 12. l." },
          { value: "Darzinu 13. l.", label: "Darzinu 13. l." },
          { value: "Darzinu 14. l.", label: "Darzinu 14. l." },
          { value: "Darzinu 15. l.", label: "Darzinu 15. l." },
          { value: "Darzinu 16. l.", label: "Darzinu 16. l." },
          { value: "Darzinu 17. l.", label: "Darzinu 17. l." },
          { value: "Darzinu 18. l.", label: "Darzinu 18. l." },
          { value: "Darzinu 19. l.", label: "Darzinu 19. l." },
          { value: "Darzinu 2. l.", label: "Darzinu 2. l." },
          { value: "Darzinu 20. l.", label: "Darzinu 20. l." },
          { value: "Darzinu 21. l.", label: "Darzinu 21. l." },
          { value: "Darzinu 22. l.", label: "Darzinu 22. l." },
          { value: "Darzinu 23. l.", label: "Darzinu 23. l." },
          { value: "Darzinu 24. l.", label: "Darzinu 24. l." },
          { value: "Darzinu 25. l.", label: "Darzinu 25. l." },
          { value: "Darzinu 26. l.", label: "Darzinu 26. l." },
          { value: "Darzinu 27. l.", label: "Darzinu 27. l." },
          { value: "Darzinu 28. l.", label: "Darzinu 28. l." },
          { value: "Darzinu 29. l.", label: "Darzinu 29. l." },
          { value: "Darzinu 3. l.", label: "Darzinu 3. l." },
          { value: "Darzinu 30. l.", label: "Darzinu 30. l." },
          { value: "Darzinu 31. l.", label: "Darzinu 31. l." },
          { value: "Darzinu 32. l.", label: "Darzinu 32. l." },
          { value: "Darzinu 33. l.", label: "Darzinu 33. l." },
          { value: "Darzinu 34. l.", label: "Darzinu 34. l." },
          { value: "Darzinu 35. l.", label: "Darzinu 35. l." },
          { value: "Darzinu 36. l.", label: "Darzinu 36. l." },
          { value: "Darzinu 38. l.", label: "Darzinu 38. l." },
          { value: "Darzinu 39. l.", label: "Darzinu 39. l." },
          { value: "Darzinu 4. l.", label: "Darzinu 4. l." },
          { value: "Darzinu 40. l.", label: "Darzinu 40. l." },
          { value: "Darzinu 41. l.", label: "Darzinu 41. l." },
          { value: "Darzinu 42. l.", label: "Darzinu 42. l." },
          { value: "Darzinu 43. l.", label: "Darzinu 43. l." },
          { value: "Darzinu 44. l.", label: "Darzinu 44. l." },
          { value: "Darzinu 45. l.", label: "Darzinu 45. l." },
          { value: "Darzinu 46. l.", label: "Darzinu 46. l." },
          { value: "Darzinu 48. l.", label: "Darzinu 48. l." },
          { value: "Darzinu 49. l.", label: "Darzinu 49. l." },
          { value: "Darzinu 5. l.", label: "Darzinu 5. l." },
          { value: "Darzinu 50. l.", label: "Darzinu 50. l." },
          { value: "Darzinu 51. l.", label: "Darzinu 51. l." },
          { value: "Darzinu 52. l.", label: "Darzinu 52. l." },
          { value: "Darzinu 53. l.", label: "Darzinu 53. l." },
          { value: "Darzinu 54. l.", label: "Darzinu 54. l." },
          { value: "Darzinu 55. l.", label: "Darzinu 55. l." },
          { value: "Darzinu 56. l.", label: "Darzinu 56. l." },
          { value: "Darzinu 57. l.", label: "Darzinu 57. l." },
          { value: "Darzinu 58. l.", label: "Darzinu 58. l." },
          { value: "Darzinu 59. l.", label: "Darzinu 59. l." },
          { value: "Darzinu 6. l.", label: "Darzinu 6. l." },
          { value: "Darzinu 60. l.", label: "Darzinu 60. l." },
          { value: "Darzinu 61. l.", label: "Darzinu 61. l." },
          { value: "Darzinu 7. l.", label: "Darzinu 7. l." },
          { value: "Darzinu 8. l.", label: "Darzinu 8. l." },
          { value: "Darzinu 9. l.", label: "Darzinu 9. l." },
          { value: "Darznieku", label: "Darznieku" },
          { value: "Daugavmalas", label: "Daugavmalas" },
          { value: "Gravju", label: "Gravju" },
          { value: "Janogu", label: "Janogu" },
          { value: "Jasminu", label: "Jasminu" },
          { value: "Jaunsaimnieku", label: "Jaunsaimnieku" },
          { value: "Kazragu", label: "Kazragu" },
          { value: "Krachu", label: "Krachu" },
          { value: "Kristines", label: "Kristines" },
          { value: "Linu", label: "Linu" },
          { value: "Malkas", label: "Malkas" },
          { value: "Marsila", label: "Marsila" },
          { value: "Maskavas", label: "Maskavas" },
          { value: "Pienenu", label: "Pienenu" },
          { value: "Plostnieku", label: "Plostnieku" },
          { value: "Ragatu", label: "Ragatu" },
          { value: "Ratnieku", label: "Ratnieku" },
          { value: "Rinkulu", label: "Rinkulu" },
          { value: "Saknu", label: "Saknu" },
          { value: "Siena", label: "Siena" },
          { value: "Tachu", label: "Tachu" },
          { value: "Taisna", label: "Taisna" },
          { value: "Taurinu", label: "Taurinu" },
          { value: "Vermelu", label: "Vermelu" },
          { value: "Vizbulu", label: "Vizbulu" },
          { value: "Zvirbulu", label: "Zvirbulu" },
        ],
      },
      {
        value: "daugavgriva",
        label: "Daugavgriva",
        streets: [
          { value: "Birzes", label: "Birzes" },
          { value: "Dzelzs", label: "Dzelzs" },
          { value: "Dzintara", label: "Dzintara" },
          { value: "Esplanades", label: "Esplanades" },
          { value: "Flotes", label: "Flotes" },
          { value: "Kaiminu", label: "Kaiminu" },
          { value: "Kapteinu", label: "Kapteinu" },
          { value: "Lepju", label: "Lepju" },
          { value: "Parades", label: "Parades" },
          { value: "Pleksnes", label: "Pleksnes" },
          { value: "Slimnicas", label: "Slimnicas" },
          { value: "Zvinu", label: "Zvinu" },
        ],
      },
      {
        value: "dreilini",
        label: "Dreilini",
        streets: [
          { value: "Aronas", label: "Aronas" },
          { value: "Baltinavas", label: "Baltinavas" },
          { value: "Bikernieku", label: "Bikernieku" },
          { value: "Deglava", label: "Deglava" },
          { value: "Dzelzavas", label: "Dzelzavas" },
          { value: "Dzilnas", label: "Dzilnas" },
          { value: "Kadiku", label: "Kadiku" },
          { value: "Kaivas", label: "Kaivas" },
          { value: "Keguma", label: "Keguma" },
          { value: "Lielvardes", label: "Lielvardes" },
          { value: "Lubanas", label: "Lubanas" },
          { value: "Strautu", label: "Strautu" },
          { value: "Ulbrokas", label: "Ulbrokas" },
          { value: "Valtera", label: "Valtera" },
        ],
      },
      {
        value: "dzeguzhkalns",
        label: "Dzeguzhkalns",
        streets: [
          { value: "Aisteres", label: "Aisteres" },
          { value: "Bullu", label: "Bullu" },
          { value: "Bumbieru", label: "Bumbieru" },
          { value: "Dagmaras", label: "Dagmaras" },
          { value: "Darza", label: "Darza" },
          { value: "Daugavgrivas", label: "Daugavgrivas" },
          { value: "Durbes", label: "Durbes" },
          { value: "Dzeguzhu", label: "Dzeguzhu" },
          { value: "Dzirciema", label: "Dzirciema" },
          { value: "Eizhenijas", label: "Eizhenijas" },
          { value: "Embutes", label: "Embutes" },
          { value: "Grodnas", label: "Grodnas" },
          { value: "Iecavas", label: "Iecavas" },
          { value: "Jaunsaules", label: "Jaunsaules" },
          { value: "Jurmalas g.", label: "Jurmalas g." },
          { value: "Kirshu", label: "Kirshu" },
          { value: "Konsula", label: "Konsula" },
          { value: "Kreslera", label: "Kreslera" },
          { value: "Kuldigas", label: "Kuldigas" },
          { value: "Lielezeres", label: "Lielezeres" },
          { value: "M. Darza", label: "M. Darza" },
          { value: "M. Stacijas", label: "M. Stacijas" },
          { value: "M. Udens", label: "M. Udens" },
          { value: "Morica", label: "Morica" },
          { value: "Pinku", label: "Pinku" },
          { value: "Pulka", label: "Pulka" },
          { value: "Purva", label: "Purva" },
          { value: "Rundales", label: "Rundales" },
          { value: "Saldus", label: "Saldus" },
          { value: "Saukas", label: "Saukas" },
          { value: "Selpils", label: "Selpils" },
          { value: "Skriveru", label: "Skriveru" },
          { value: "Skrundas", label: "Skrundas" },
          { value: "Slokas", label: "Slokas" },
          { value: "Stacijas", label: "Stacijas" },
          { value: "Tapeshu", label: "Tapeshu" },
          { value: "Tvaikonu", label: "Tvaikonu" },
          { value: "Udens", label: "Udens" },
          { value: "Usmas", label: "Usmas" },
          { value: "Vilnas", label: "Vilnas" },
          { value: "Vircavas", label: "Vircavas" },
        ],
      },
      {
        value: "grizinkalns",
        label: "Grizinkalns",
        streets: [
          { value: "Alauksta", label: "Alauksta" },
          { value: "Artilerijas", label: "Artilerijas" },
          { value: "Asara", label: "Asara" },
          { value: "Ata", label: "Ata" },
          { value: "Augshiela", label: "Augshiela" },
          { value: "Avotu", label: "Avotu" },
          { value: "Barona", label: "Barona" },
          { value: "Brivibas", label: "Brivibas" },
          { value: "Chaka", label: "Chaka" },
          { value: "Darzauglu", label: "Darzauglu" },
          { value: "Deglava", label: "Deglava" },
          { value: "Erglu", label: "Erglu" },
          { value: "Krasotaju", label: "Krasotaju" },
          { value: "Laboratorijas", label: "Laboratorijas" },
          { value: "Lauku", label: "Lauku" },
          { value: "Lienes", label: "Lienes" },
          { value: "Pernavas", label: "Pernavas" },
          { value: "Rudolfa", label: "Rudolfa" },
          { value: "Sapieru", label: "Sapieru" },
          { value: "Stadiona", label: "Stadiona" },
          { value: "Tallinas", label: "Tallinas" },
          { value: "Terezes", label: "Terezes" },
          { value: "Valmieras", label: "Valmieras" },
          { value: "Varnu", label: "Varnu" },
          { value: "Zemitana", label: "Zemitana" },
          { value: "Zvaigzhnu", label: "Zvaigzhnu" },
        ],
      },
      {
        value: "ilguciems",
        label: "Ilguciems",
        streets: [
          { value: "Balta", label: "Balta" },
          { value: "Benes", label: "Benes" },
          { value: "Bolderajas", label: "Bolderajas" },
          { value: "Bullu", label: "Bullu" },
          { value: "Cementa", label: "Cementa" },
          { value: "Dagmaras", label: "Dagmaras" },
          { value: "Daugavgrivas", label: "Daugavgrivas" },
          { value: "Davida", label: "Davida" },
          { value: "Dundagas", label: "Dundagas" },
          { value: "Dzirciema", label: "Dzirciema" },
          { value: "Edoles", label: "Edoles" },
          { value: "Gara", label: "Gara" },
          { value: "Grivas", label: "Grivas" },
          { value: "Grodnas", label: "Grodnas" },
          { value: "Ilguciema", label: "Ilguciema" },
          { value: "Kleistu", label: "Kleistu" },
          { value: "Kreslera", label: "Kreslera" },
          { value: "Kurzemes pr.", label: "Kurzemes pr." },
          { value: "Lidonu", label: "Lidonu" },
          { value: "Lielezeres", label: "Lielezeres" },
          { value: "Lilijas", label: "Lilijas" },
          { value: "M. Bolderajas", label: "M. Bolderajas" },
          { value: "M. Bullu", label: "M. Bullu" },
          { value: "M. Shkersiela", label: "M. Shkersiela" },
          { value: "Majoru", label: "Majoru" },
          { value: "Melluzhu", label: "Melluzhu" },
          { value: "Motoru", label: "Motoru" },
          { value: "Olaines", label: "Olaines" },
          { value: "Palangas", label: "Palangas" },
          { value: "Renges", label: "Renges" },
          { value: "Riekstu", label: "Riekstu" },
          { value: "Saulgozhu", label: "Saulgozhu" },
          { value: "Shaulu", label: "Shaulu" },
          { value: "Shkersiela", label: "Shkersiela" },
          { value: "Shmita", label: "Shmita" },
          { value: "Skodas", label: "Skodas" },
          { value: "Skujenes", label: "Skujenes" },
          { value: "Slokas", label: "Slokas" },
          { value: "Snikeres", label: "Snikeres" },
          { value: "Spilves", label: "Spilves" },
          { value: "Stures", label: "Stures" },
          { value: "Tvaikonu", label: "Tvaikonu" },
          { value: "V. Bullu", label: "V. Bullu" },
          { value: "Vaidelotes", label: "Vaidelotes" },
          { value: "Varkalu", label: "Varkalu" },
          { value: "Verdinu", label: "Verdinu" },
          { value: "Vilnas", label: "Vilnas" },
          { value: "Virbu", label: "Virbu" },
        ],
      },
      {
        value: "imanta",
        label: "Imanta",
        streets: [
          { value: "Airites", label: "Airites" },
          { value: "Akaciju", label: "Akaciju" },
          {
            value: "Anninmuizhas boulevard",
            label: "Anninmuizhas boulevard",
          },
          { value: "Anninmuizhas street", label: "Anninmuizhas street" },
          { value: "Auru", label: "Auru" },
          { value: "Beberbeku", label: "Beberbeku" },
          { value: "Bebru", label: "Bebru" },
          { value: "Bolderajas", label: "Bolderajas" },
          { value: "Bullu", label: "Bullu" },
          { value: "Cerinu", label: "Cerinu" },
          { value: "Dammes", label: "Dammes" },
          { value: "Dubultu", label: "Dubultu" },
          { value: "Dumbraja", label: "Dumbraja" },
          { value: "Dunalkas", label: "Dunalkas" },
          { value: "Enas", label: "Enas" },
          { value: "Erkshku", label: "Erkshku" },
          { value: "Imantas", label: "Imantas" },
          { value: "Imantas 1. l.", label: "Imantas 1. l." },
          { value: "Imantas 3. l.", label: "Imantas 3. l." },
          { value: "Imantas 4. l.", label: "Imantas 4. l." },
          { value: "Imantas 5. l.", label: "Imantas 5. l." },
          { value: "Imantas 6. l.", label: "Imantas 6. l." },
          { value: "Imantas 7. l.", label: "Imantas 7. l." },
          { value: "Imantas 8. l.", label: "Imantas 8. l." },
          { value: "Jurmalas g.", label: "Jurmalas g." },
          { value: "Kandavas", label: "Kandavas" },
          { value: "Kiburgas", label: "Kiburgas" },
          { value: "Kleistu", label: "Kleistu" },
          { value: "Kooperativa", label: "Kooperativa" },
          { value: "Krutes", label: "Krutes" },
          { value: "Kurpnieku", label: "Kurpnieku" },
          { value: "Kurzemes pr.", label: "Kurzemes pr." },
          { value: "Lazdu", label: "Lazdu" },
          { value: "Loka", label: "Loka" },
          { value: "M. Bolderajas", label: "M. Bolderajas" },
          { value: "M. Bullu", label: "M. Bullu" },
          { value: "M. Kandavas", label: "M. Kandavas" },
          { value: "M. Krūmu", label: "M. Krūmu" },
          { value: "M. Loka", label: "M. Loka" },
          { value: "M. Shkersiela", label: "M. Shkersiela" },
          { value: "M. Zolitudes", label: "M. Zolitudes" },
          { value: "Majoru", label: "Majoru" },
          { value: "Melluzhu", label: "Melluzhu" },
          { value: "Nicas", label: "Nicas" },
          { value: "Observatorijas", label: "Observatorijas" },
          { value: "Piladzhu", label: "Piladzhu" },
          { value: "Progresa", label: "Progresa" },
          { value: "Purenu", label: "Purenu" },
          { value: "Pures", label: "Pures" },
          { value: "Purva", label: "Purva" },
          { value: "Raktes", label: "Raktes" },
          { value: "Ravas", label: "Ravas" },
          { value: "Rigondas g.", label: "Rigondas g." },
          { value: "Rinka", label: "Rinka" },
          { value: "Sakas", label: "Sakas" },
          { value: "Seces", label: "Seces" },
          { value: "Shkersiela", label: "Shkersiela" },
          { value: "Shmita", label: "Shmita" },
          { value: "Sidraba", label: "Sidraba" },
          { value: "Slokas", label: "Slokas" },
          { value: "Snikeres", label: "Snikeres" },
          { value: "Spodras", label: "Spodras" },
          { value: "Talavas g.", label: "Talavas g." },
          { value: "Tashu", label: "Tashu" },
          { value: "Trisstura", label: "Trisstura" },
          { value: "Uzhavas", label: "Uzhavas" },
          { value: "Varkalu", label: "Varkalu" },
          { value: "Varves", label: "Varves" },
          { value: "Vecumnieku", label: "Vecumnieku" },
          { value: "Vibotnu", label: "Vibotnu" },
          { value: "Viksnes", label: "Viksnes" },
          { value: "Virbu", label: "Virbu" },
          { value: "Zardu", label: "Zardu" },
          { value: "Zentenes", label: "Zentenes" },
          { value: "Ziedona", label: "Ziedona" },
          { value: "Zilu", label: "Zilu" },
          { value: "Zolitudes", label: "Zolitudes" },
          { value: "Zuru", label: "Zuru" },
        ],
      },
      {
        value: "janjavarti",
        label: "Janjavarti",
        streets: [
          { value: "Janavartu", label: "Janavartu" },
          { value: "Katlakalna", label: "Katlakalna" },
          { value: "Krustpils", label: "Krustpils" },
          { value: "Lubanas", label: "Lubanas" },
          { value: "Rencenu", label: "Rencenu" },
          { value: "Slavu", label: "Slavu" },
          { value: "Straupes", label: "Straupes" },
          { value: "Zhiguru", label: "Zhiguru" },
        ],
      },
      {
        value: "jaunciems",
        label: "Jaunciems",
        streets: [
          { value: "Aka", label: "Aka" },
          { value: "Araju", label: "Araju" },
          { value: "Avenu", label: "Avenu" },
          { value: "Baraviku", label: "Baraviku" },
          { value: "Gailenu", label: "Gailenu" },
          { value: "Jaunciema 1. cr. l.", label: "Jaunciema 1. cr. l." },
          { value: "Jaunciema 10. cr. l.", label: "Jaunciema 10. cr. l." },
          { value: "Jaunciema 2. cr. l.", label: "Jaunciema 2. cr. l." },
          { value: "Jaunciema 2. l.", label: "Jaunciema 2. l." },
          { value: "Jaunciema 3. cr. l.", label: "Jaunciema 3. cr. l." },
          { value: "Jaunciema 3. l.", label: "Jaunciema 3. l." },
          { value: "Jaunciema 4. cr. l.", label: "Jaunciema 4. cr. l." },
          { value: "Jaunciema 4. l.", label: "Jaunciema 4. l." },
          { value: "Jaunciema 5. cr. l.", label: "Jaunciema 5. cr. l." },
          { value: "Jaunciema 5. l.", label: "Jaunciema 5. l." },
          { value: "Jaunciema 6. cr. l.", label: "Jaunciema 6. cr. l." },
          { value: "Jaunciema 6. l.", label: "Jaunciema 6. l." },
          { value: "Jaunciema 7. cr. l.", label: "Jaunciema 7. cr. l." },
          { value: "Jaunciema 8. cr. l.", label: "Jaunciema 8. cr. l." },
          { value: "Jaunciema 9. cr. l.", label: "Jaunciema 9. cr. l." },
          { value: "Jaunciema g.", label: "Jaunciema g." },
          { value: "Kalmju", label: "Kalmju" },
          { value: "Lapsukalna", label: "Lapsukalna" },
          { value: "Lichu", label: "Lichu" },
          { value: "Mezhvidu", label: "Mezhvidu" },
          { value: "Niedru", label: "Niedru" },
          { value: "Rudzu", label: "Rudzu" },
          { value: "Senlejas", label: "Senlejas" },
          { value: "Shalku", label: "Shalku" },
          { value: "Steku", label: "Steku" },
          { value: "Trīsciema 1. līnija", label: "Trīsciema 1. līnija" },
          { value: "Trīsciema 2. līnija", label: "Trīsciema 2. līnija" },
          { value: "Trīsciema 3. līnija", label: "Trīsciema 3. līnija" },
          { value: "Trīsciema 4. līnija", label: "Trīsciema 4. līnija" },
          { value: "Trīsciema 5. līnija", label: "Trīsciema 5. līnija" },
          { value: "Trīsciema 6. līnija", label: "Trīsciema 6. līnija" },
          { value: "Trīsciema 7. līnija", label: "Trīsciema 7. līnija" },
          { value: "Trīsciema 8. līnija", label: "Trīsciema 8. līnija" },
          { value: "Trīsciema 9. līnija", label: "Trīsciema 9. līnija" },
          { value: "Vadu", label: "Vadu" },
          { value: "Varpu", label: "Varpu" },
          { value: "Vedas", label: "Vedas" },
        ],
      },
      {
        value: "jaunmilgravis",
        label: "Jaunmilgravis",
        streets: [
          { value: "Adazhu", label: "Adazhu" },
          { value: "Aplokciema", label: "Aplokciema" },
          { value: "Carnikavas", label: "Carnikavas" },
          { value: "Degvielas", label: "Degvielas" },
          { value: "Ezera", label: "Ezera" },
          { value: "Jaunciema g.", label: "Jaunciema g." },
          { value: "Ledurgas", label: "Ledurgas" },
          { value: "Milgravja", label: "Milgravja" },
          { value: "Ostas", label: "Ostas" },
          { value: "Sliezhu", label: "Sliezhu" },
          { value: "Spridisha", label: "Spridisha" },
          { value: "Tvaika", label: "Tvaika" },
          { value: "Viestura pr.", label: "Viestura pr." },
          { value: "Vitrupes", label: "Vitrupes" },
        ],
      },
      {
        value: "katlakalns",
        label: "Katlakalns",
        streets: [
          { value: "Bauskas", label: "Bauskas" },
          { value: "Brunavas", label: "Brunavas" },
          { value: "Bukaisu", label: "Bukaisu" },
          { value: "Ceraukstes", label: "Ceraukstes" },
          { value: "Chakstes g.", label: "Chakstes g." },
          { value: "Doles", label: "Doles" },
          { value: "Gulbju", label: "Gulbju" },
          { value: "Kometas", label: "Kometas" },
          { value: "Lapotnes", label: "Lapotnes" },
          { value: "Lejupes", label: "Lejupes" },
          { value: "M. Bauskas", label: "M. Bauskas" },
          { value: "Padures", label: "Padures" },
          { value: "Rubas", label: "Rubas" },
          { value: "Shautuves", label: "Shautuves" },
          { value: "Stabules", label: "Stabules" },
          { value: "Taures", label: "Taures" },
          { value: "Upju", label: "Upju" },
          { value: "Valdlaucu", label: "Valdlaucu" },
          { value: "Zalu", label: "Zalu" },
          { value: "Ziepniekkalna", label: "Ziepniekkalna" },
        ],
      },
      {
        value: "kengarags",
        label: "Kengarags",
        streets: [
          { value: "Aglonas", label: "Aglonas" },
          { value: "Austuves", label: "Austuves" },
          { value: "Aviacijas", label: "Aviacijas" },
          { value: "Bultu", label: "Bultu" },
          { value: "Dubnas", label: "Dubnas" },
          { value: "Eglaines", label: "Eglaines" },
          { value: "Festivala", label: "Festivala" },
          { value: "Gludas", label: "Gludas" },
          { value: "Ikshkiles", label: "Ikshkiles" },
          { value: "Izvaltas", label: "Izvaltas" },
          { value: "J. Rancana", label: "J. Rancana" },
          { value: "Janavartu", label: "Janavartu" },
          { value: "Kaniera", label: "Kaniera" },
          { value: "Katlakalna", label: "Katlakalna" },
          { value: "Kengaraga", label: "Kengaraga" },
          { value: "Krustpils", label: "Krustpils" },
          { value: "Kvadrata", label: "Kvadrata" },
          { value: "Kvieshu", label: "Kvieshu" },
          { value: "Linavas", label: "Linavas" },
          { value: "Lokomotives", label: "Lokomotives" },
          { value: "Lubanas", label: "Lubanas" },
          { value: "Malnavas", label: "Malnavas" },
          { value: "Maltas", label: "Maltas" },
          { value: "Maskavas", label: "Maskavas" },
          { value: "Naujenes", label: "Naujenes" },
          { value: "Parmiju", label: "Parmiju" },
          { value: "Pelnu", label: "Pelnu" },
          { value: "Plostu", label: "Plostu" },
          { value: "Prushu", label: "Prushu" },
          { value: "Randu", label: "Randu" },
          { value: "Rasas", label: "Rasas" },
          { value: "Rencenu", label: "Rencenu" },
          { value: "Rushonu", label: "Rushonu" },
          { value: "Saites", label: "Saites" },
          { value: "Salaspils", label: "Salaspils" },
          { value: "Slavu", label: "Slavu" },
          { value: "Spalu", label: "Spalu" },
          { value: "Straupes", label: "Straupes" },
          { value: "Urgu", label: "Urgu" },
          { value: "Vishku", label: "Vishku" },
          { value: "Vitenu", label: "Vitenu" },
          { value: "Vizlas", label: "Vizlas" },
          { value: "Zhiguru", label: "Zhiguru" },
        ],
      },
      {
        value: "kiburga",
        label: "Kiburga",
        streets: [
          { value: "Airites", label: "Airites" },
          { value: "Beberbeku", label: "Beberbeku" },
          { value: "Cerinu", label: "Cerinu" },
          { value: "Dunalkas", label: "Dunalkas" },
          { value: "Enas", label: "Enas" },
          { value: "Erkshku", label: "Erkshku" },
          { value: "Jurmalas g.", label: "Jurmalas g." },
          { value: "Kiburgas", label: "Kiburgas" },
          { value: "Kurzemes pr.", label: "Kurzemes pr." },
          { value: "Lazdu", label: "Lazdu" },
          { value: "M. Zolitudes", label: "M. Zolitudes" },
          { value: "Purenu", label: "Purenu" },
          { value: "Ravas", label: "Ravas" },
          { value: "Slokas", label: "Slokas" },
          { value: "Tashu", label: "Tashu" },
          { value: "Vibotnu", label: "Vibotnu" },
          { value: "Ziedona", label: "Ziedona" },
          { value: "Zolitudes", label: "Zolitudes" },
        ],
      },
      {
        value: "kipsala",
        label: "Kipsala",
        streets: [
          { value: "Azenes", label: "Azenes" },
          { value: "Balasta d.", label: "Balasta d." },
          { value: "Enkura", label: "Enkura" },
          { value: "Gipsha", label: "Gipsha" },
          { value: "Kaiju", label: "Kaiju" },
          { value: "Kipsalas", label: "Kipsalas" },
          { value: "Lochu", label: "Lochu" },
          { value: "M. Balasta d.", label: "M. Balasta d." },
          { value: "M. Kaiju", label: "M. Kaiju" },
          { value: "Matrozhu", label: "Matrozhu" },
          { value: "Oglu", label: "Oglu" },
          { value: "Ronsalas", label: "Ronsalas" },
          { value: "Tiklu", label: "Tiklu" },
          { value: "Valdemara", label: "Valdemara" },
          { value: "Zvejnieku", label: "Zvejnieku" },
        ],
      },
      {
        value: "kleisti",
        label: "Kleisti",
        streets: [
          { value: "Bullu", label: "Bullu" },
          { value: "Kleistu", label: "Kleistu" },
          { value: "Kurzemes pr.", label: "Kurzemes pr." },
          { value: "Ratsupites", label: "Ratsupites" },
        ],
      },
      {
        value: "kliversala",
        label: "Kliversala",
        streets: [
          { value: "Akmenu", label: "Akmenu" },
          { value: "H. Celmiņa", label: "H. Celmiņa" },
          { value: "Jelgavas", label: "Jelgavas" },
          { value: "Kliveru", label: "Kliveru" },
          { value: "Kugu", label: "Kugu" },
          { value: "Mukusalas", label: "Mukusalas" },
          { value: "Ranka d.", label: "Ranka d." },
          { value: "Trijadibas", label: "Trijadibas" },
          { value: "Uzvaras boul.", label: "Uzvaras boul." },
          { value: "Valguma", label: "Valguma" },
        ],
      },
      {
        value: "krasta-st-area",
        label: "Krasta st. area",
        streets: [
          { value: "Balvu", label: "Balvu" },
          { value: "Dunavas", label: "Dunavas" },
          { value: "Dzervju", label: "Dzervju" },
          { value: "Fridrika", label: "Fridrika" },
          { value: "Gredu", label: "Gredu" },
          { value: "Indrupes", label: "Indrupes" },
          { value: "Kojusalas", label: "Kojusalas" },
          { value: "Krasta", label: "Krasta" },
          { value: "Kridenera d.", label: "Kridenera d." },
          { value: "Lachplesha", label: "Lachplesha" },
          { value: "Lubanas", label: "Lubanas" },
          { value: "M. Krasta", label: "M. Krasta" },
          { value: "Maskavas", label: "Maskavas" },
          { value: "Ogres", label: "Ogres" },
          { value: "Plavinu", label: "Plavinu" },
          { value: "Radzin embankment", label: "Radzin embankment" },
          { value: "Ritupes", label: "Ritupes" },
          { value: "Salacas", label: "Salacas" },
          { value: "Salaspils", label: "Salaspils" },
          { value: "Slavu", label: "Slavu" },
          { value: "Zilupes", label: "Zilupes" },
        ],
      },
      {
        value: "kremeri",
        label: "Kremeri",
        streets: [
          { value: "Daugavgrivas h.", label: "Daugavgrivas h." },
          { value: "Kremeri", label: "Kremeri" },
          { value: "Zila", label: "Zila" },
        ],
      },
      {
        value: "kundzinsala",
        label: "Kundzinsala",
        streets: [
          { value: "Kundzinsalas 1. l.", label: "Kundzinsalas 1. l." },
          {
            value: "Kundzinsalas 1. cr. l.",
            label: "Kundzinsalas 1. cr. l.",
          },
          {
            value: "Kundzinsalas 10. cr. l.",
            label: "Kundzinsalas 10. cr. l.",
          },
          { value: "Kundzinsalas 10. l.", label: "Kundzinsalas 10. l." },
          { value: "Kundzinsalas 11. l.", label: "Kundzinsalas 11. l." },
          { value: "Kundzinsalas 12. l.", label: "Kundzinsalas 12. l." },
          { value: "Kundzinsalas 13. l.", label: "Kundzinsalas 13. l." },
          { value: "Kundzinsalas 14. l.", label: "Kundzinsalas 14. l." },
          { value: "Kundzinsalas 15. l.", label: "Kundzinsalas 15. l." },
          {
            value: "Kundzinsalas 15. cr. l.",
            label: "Kundzinsalas 15. cr. l.",
          },
          { value: "Kundzinsalas 16. l.", label: "Kundzinsalas 16. l." },
          {
            value: "Kundzinsalas 2. cr. l.",
            label: "Kundzinsalas 2. cr. l.",
          },
          { value: "Kundzinsalas 2. l.", label: "Kundzinsalas 2. l." },
          { value: "Kundzinsalas 3. l.", label: "Kundzinsalas 3. l." },
          { value: "Kundzinsalas 4. l.", label: "Kundzinsalas 4. l." },
          {
            value: "Kundzinsalas 4. cr. l.",
            label: "Kundzinsalas 4. cr. l.",
          },
          {
            value: "Kundzinsalas 5. cr. l.",
            label: "Kundzinsalas 5. cr. l.",
          },
          { value: "Kundzinsalas 6. l.", label: "Kundzinsalas 6. l." },
          {
            value: "Kundzinsalas 6. cr. l.",
            label: "Kundzinsalas 6. cr. l.",
          },
          { value: "Kundzinsalas 7. l.", label: "Kundzinsalas 7. l." },
          {
            value: "Kundzinsalas 7. cr. l.",
            label: "Kundzinsalas 7. cr. l.",
          },
          { value: "Kundzinsalas 8. l.", label: "Kundzinsalas 8. l." },
          {
            value: "Kundzinsalas 8. cr. l.",
            label: "Kundzinsalas 8. cr. l.",
          },
          { value: "Kundzinsalas 9. l.", label: "Kundzinsalas 9. l." },
          {
            value: "Kundzinsalas 9. cr. l.",
            label: "Kundzinsalas 9. cr. l.",
          },
        ],
      },
      {
        value: "lucavsala",
        label: "Lucavsala",
        streets: [{ value: "Lucavsala", label: "Lucavsala" }],
      },
      {
        value: "mangali",
        label: "Mangali",
        streets: [
          { value: "Adazhu", label: "Adazhu" },
          { value: "Aizsaules", label: "Aizsaules" },
          { value: "Aleksha", label: "Aleksha" },
          { value: "Allazhu", label: "Allazhu" },
          { value: "Aplokciema", label: "Aplokciema" },
          { value: "Aptiekas", label: "Aptiekas" },
          { value: "Atputas al.", label: "Atputas al." },
          { value: "Azhu", label: "Azhu" },
          { value: "Bergenas", label: "Bergenas" },
          { value: "Bergmana", label: "Bergmana" },
          { value: "Bernatu", label: "Bernatu" },
          { value: "Briezhu", label: "Briezhu" },
          { value: "Bukultu", label: "Bukultu" },
          { value: "Carnikavas", label: "Carnikavas" },
          { value: "Celinieku", label: "Celinieku" },
          { value: "Chiekuru", label: "Chiekuru" },
          { value: "Cimzes", label: "Cimzes" },
          { value: "Dambja", label: "Dambja" },
          { value: "Darzina", label: "Darzina" },
          { value: "Degvielas", label: "Degvielas" },
          { value: "Duntes", label: "Duntes" },
          { value: "Eksporta", label: "Eksporta" },
          { value: "Ezera", label: "Ezera" },
          { value: "Ezermalas", label: "Ezermalas" },
          { value: "Ganibu d.", label: "Ganibu d." },
          { value: "Gatartas", label: "Gatartas" },
          { value: "Gaujas", label: "Gaujas" },
          { value: "Gaujienas", label: "Gaujienas" },
          { value: "Gdanskas", label: "Gdanskas" },
          { value: "Glika", label: "Glika" },
          { value: "Hamburgas", label: "Hamburgas" },
          { value: "Hanzas", label: "Hanzas" },
          { value: "Hapsalas", label: "Hapsalas" },
          { value: "Hirsa", label: "Hirsa" },
          { value: "Ilzenes", label: "Ilzenes" },
          { value: "Inchukalna", label: "Inchukalna" },
          { value: "Janshevska pr.", label: "Janshevska pr." },
          { value: "Jauna Mezaparka", label: "Jauna Mezaparka" },
          { value: "Jaunciema g.", label: "Jaunciema g." },
          { value: "Kadiku", label: "Kadiku" },
          { value: "Kakasekla d.", label: "Kakasekla d." },
          { value: "Kempes", label: "Kempes" },
          { value: "Kishezera", label: "Kishezera" },
          { value: "Klaipedas", label: "Klaipedas" },
          { value: "Kokneses pr.", label: "Kokneses pr." },
          { value: "Krapes", label: "Krapes" },
          { value: "Lades", label: "Lades" },
          { value: "Laktas", label: "Laktas" },
          { value: "Lapstu", label: "Lapstu" },
          { value: "Ledurgas", label: "Ledurgas" },
          { value: "Libekas", label: "Libekas" },
          { value: "Ligatnes", label: "Ligatnes" },
          { value: "Limbazhu", label: "Limbazhu" },
          { value: "Lugazhu", label: "Lugazhu" },
          { value: "M. Vejzaksala", label: "M. Vejzaksala" },
          { value: "Mailes", label: "Mailes" },
          { value: "Matera", label: "Matera" },
          { value: "Mezha pr.", label: "Mezha pr." },
          { value: "Mezhnoras", label: "Mezhnoras" },
          { value: "Milgravja", label: "Milgravja" },
          { value: "Mores", label: "Mores" },
          { value: "Nesaules", label: "Nesaules" },
          { value: "Olava", label: "Olava" },
          { value: "Ostas", label: "Ostas" },
          { value: "Ostas pr.", label: "Ostas pr." },
          { value: "Ozolu", label: "Ozolu" },
          { value: "Patversmes", label: "Patversmes" },
          { value: "Pavu", label: "Pavu" },
          { value: "Pededzes", label: "Pededzes" },
          { value: "Peterupes", label: "Peterupes" },
          { value: "Poruka", label: "Poruka" },
          { value: "Priezhu", label: "Priezhu" },
          { value: "Pulkv. Briezha", label: "Pulkv. Briezha" },
          { value: "Ramulu", label: "Ramulu" },
          { value: "Rankas", label: "Rankas" },
          { value: "Sakses", label: "Sakses" },
          { value: "Saremas", label: "Saremas" },
          { value: "Sarkandaugavas", label: "Sarkandaugavas" },
          { value: "Shchecinas", label: "Shchecinas" },
          { value: "Siguldas pr.", label: "Siguldas pr." },
          { value: "Simana", label: "Simana" },
          { value: "Skanstes", label: "Skanstes" },
          { value: "Sliezhu", label: "Sliezhu" },
          { value: "Sofijas", label: "Sofijas" },
          { value: "Sporta", label: "Sporta" },
          { value: "Spridisha", label: "Spridisha" },
          { value: "Stendera", label: "Stendera" },
          { value: "Stokholmas", label: "Stokholmas" },
          { value: "Sudrabu Edzhus", label: "Sudrabu Edzhus" },
          { value: "Talejas", label: "Talejas" },
          { value: "Tilta", label: "Tilta" },
          { value: "Trapenes", label: "Trapenes" },
          { value: "Tvaika", label: "Tvaika" },
          { value: "Uriekstes", label: "Uriekstes" },
          { value: "Varonu", label: "Varonu" },
          { value: "Vatsona", label: "Vatsona" },
          { value: "Vejzaksala", label: "Vejzaksala" },
          { value: "Velmes", label: "Velmes" },
          { value: "Viestura pr.", label: "Viestura pr." },
          { value: "Visbijas pr.", label: "Visbijas pr." },
          { value: "Vitolu", label: "Vitolu" },
          { value: "Vitrupes", label: "Vitrupes" },
          { value: "Zageru", label: "Zageru" },
          { value: "Zemgala gatve", label: "Zemgala gatve" },
          { value: "Ziemelu", label: "Ziemelu" },
          { value: "Zushu", label: "Zushu" },
        ],
      },
      {
        value: "mangalsala",
        label: "Mangalsala",
        streets: [
          { value: "Albatrosu", label: "Albatrosu" },
          { value: "Audupes", label: "Audupes" },
          { value: "Bakas", label: "Bakas" },
          { value: "Judzes", label: "Judzes" },
          { value: "Mangalsalas", label: "Mangalsalas" },
          { value: "Mangalu pr.", label: "Mangalu pr." },
          { value: "Ovishu", label: "Ovishu" },
          { value: "Paeglu", label: "Paeglu" },
          { value: "Piekrastes", label: "Piekrastes" },
          { value: "Saivas", label: "Saivas" },
          { value: "Stavvadu", label: "Stavvadu" },
          { value: "Traleru", label: "Traleru" },
          { value: "Vadugunu", label: "Vadugunu" },
          { value: "Veiksmes", label: "Veiksmes" },
          { value: "Venteru", label: "Venteru" },
          { value: "Vizhnu", label: "Vizhnu" },
          { value: "Zvejniekciema", label: "Zvejniekciema" },
        ],
      },
      {
        value: "maskavas-priekshpilseta",
        label: "Maskavas priekshpilseta",
        streets: [
          { value: "13. janvara", label: "13. janvara" },
          { value: "Abrenes", label: "Abrenes" },
          { value: "Aiviekstes", label: "Aiviekstes" },
          { value: "Akademijas pl.", label: "Akademijas pl." },
          { value: "Avotu", label: "Avotu" },
          { value: "Balvu", label: "Balvu" },
          { value: "Barddzinu", label: "Barddzinu" },
          { value: "Birznieka-Upisha", label: "Birznieka-Upisha" },
          { value: "Centraltirgus", label: "Centraltirgus" },
          { value: "Chaka", label: "Chaka" },
          { value: "Dagdas", label: "Dagdas" },
          { value: "Daugavpils", label: "Daugavpils" },
          { value: "Dricanu", label: "Dricanu" },
          { value: "Dzirnavu", label: "Dzirnavu" },
          { value: "Ebreju", label: "Ebreju" },
          { value: "Elijas", label: "Elijas" },
          { value: "Elizabetes", label: "Elizabetes" },
          { value: "Fridrika", label: "Fridrika" },
          { value: "Gaizina", label: "Gaizina" },
          { value: "Gogola", label: "Gogola" },
          { value: "Grebenshchikova", label: "Grebenshchikova" },
          { value: "Gredu", label: "Gredu" },
          { value: "Isa", label: "Isa" },
          { value: "Jekabpils", label: "Jekabpils" },
          { value: "Jersikas", label: "Jersikas" },
          { value: "Jezusbaznicas", label: "Jezusbaznicas" },
          { value: "Kalna", label: "Kalna" },
          { value: "Kalupes", label: "Kalupes" },
          { value: "Katolu", label: "Katolu" },
          { value: "Kojusalas", label: "Kojusalas" },
          { value: "Kraslavas", label: "Kraslavas" },
          { value: "Krasta", label: "Krasta" },
          { value: "Lachplesha", label: "Lachplesha" },
          { value: "Lauvas", label: "Lauvas" },
          { value: "Lazdonas", label: "Lazdonas" },
          { value: "Liksnas", label: "Liksnas" },
          { value: "Lipkes", label: "Lipkes" },
          { value: "Lomonosova", label: "Lomonosova" },
          { value: "Lubanas", label: "Lubanas" },
          { value: "Ludzas", label: "Ludzas" },
          { value: "M. Kalna", label: "M. Kalna" },
          { value: "M. Krasta", label: "M. Krasta" },
          { value: "M. Lachplesha", label: "M. Lachplesha" },
          { value: "M. Lubanas", label: "M. Lubanas" },
          { value: "Marijas", label: "Marijas" },
          { value: "Maskavas", label: "Maskavas" },
          { value: "Negu", label: "Negu" },
          { value: "Pirts", label: "Pirts" },
          { value: "Pragas", label: "Pragas" },
          { value: "Pupolu", label: "Pupolu" },
          { value: "Pushkina", label: "Pushkina" },
          { value: "Radzina", label: "Radzina" },
          { value: "Reznas", label: "Reznas" },
          { value: "Riepnieku", label: "Riepnieku" },
          { value: "Rusina", label: "Rusina" },
          { value: "Sadovnikova", label: "Sadovnikova" },
          { value: "Saikavas", label: "Saikavas" },
          { value: "Salaspils", label: "Salaspils" },
          { value: "Sarkana", label: "Sarkana" },
          { value: "Satekles", label: "Satekles" },
          { value: "Shaura", label: "Shaura" },
          { value: "Skrindu", label: "Skrindu" },
          { value: "Spidolas", label: "Spidolas" },
          { value: "Spikeru", label: "Spikeru" },
          { value: "Stacijas pl.", label: "Stacijas pl." },
          { value: "Strugu", label: "Strugu" },
          { value: "Tejas", label: "Tejas" },
          { value: "Timoteja", label: "Timoteja" },
          { value: "Toma", label: "Toma" },
          { value: "Turgeneva", label: "Turgeneva" },
          { value: "Varaklanu", label: "Varaklanu" },
          { value: "Vilanu", label: "Vilanu" },
          { value: "Virsaishu", label: "Virsaishu" },
          { value: "Visvalzha", label: "Visvalzha" },
        ],
      },
      {
        value: "mezhapark",
        label: "Mezhapark",
        streets: [
          { value: "Aizsaules", label: "Aizsaules" },
          { value: "Atputas al.", label: "Atputas al." },
          { value: "Bergenas", label: "Bergenas" },
          { value: "Bergmana", label: "Bergmana" },
          { value: "Bernatu", label: "Bernatu" },
          { value: "Cimzes", label: "Cimzes" },
          { value: "Darzina", label: "Darzina" },
          { value: "Ezermalas", label: "Ezermalas" },
          { value: "Gatartas", label: "Gatartas" },
          { value: "Gaujas", label: "Gaujas" },
          { value: "Gdanskas", label: "Gdanskas" },
          { value: "Glika", label: "Glika" },
          { value: "Hamburgas", label: "Hamburgas" },
          { value: "Inchukalna", label: "Inchukalna" },
          { value: "Janshevska pr.", label: "Janshevska pr." },
          { value: "Jauna Mezaparka", label: "Jauna Mezaparka" },
          { value: "Kempes", label: "Kempes" },
          { value: "Kishezera", label: "Kishezera" },
          { value: "Klaipedas", label: "Klaipedas" },
          { value: "Kokneses pr.", label: "Kokneses pr." },
          { value: "Libekas", label: "Libekas" },
          { value: "Ligatnes", label: "Ligatnes" },
          { value: "Mailes", label: "Mailes" },
          { value: "Matera", label: "Matera" },
          { value: "Mezha pr.", label: "Mezha pr." },
          { value: "Mezhnoras", label: "Mezhnoras" },
          { value: "Nesaules", label: "Nesaules" },
          { value: "Olava", label: "Olava" },
          { value: "Ostas pr.", label: "Ostas pr." },
          { value: "Pavu", label: "Pavu" },
          { value: "Peterupes", label: "Peterupes" },
          { value: "Poruka", label: "Poruka" },
          { value: "Sakses", label: "Sakses" },
          { value: "Shchecinas", label: "Shchecinas" },
          { value: "Siguldas pr.", label: "Siguldas pr." },
          { value: "Stendera", label: "Stendera" },
          { value: "Stokholmas", label: "Stokholmas" },
          { value: "Sudrabu Edzhus", label: "Sudrabu Edzhus" },
          { value: "Talejas", label: "Talejas" },
          { value: "Tilta", label: "Tilta" },
          { value: "Varonu", label: "Varonu" },
          { value: "Vatsona", label: "Vatsona" },
          { value: "Velmes", label: "Velmes" },
          { value: "Viestura pr.", label: "Viestura pr." },
          { value: "Visbijas pr.", label: "Visbijas pr." },
          { value: "Zemgala gatve", label: "Zemgala gatve" },
        ],
      },
      {
        value: "mezhciems",
        label: "Mezhciems",
        streets: [
          { value: "Bikernieku", label: "Bikernieku" },
          { value: "Celmu", label: "Celmu" },
          { value: "Druvienas", label: "Druvienas" },
          { value: "Eizenshteina", label: "Eizenshteina" },
          { value: "Gailezera", label: "Gailezera" },
          { value: "Hipokrata", label: "Hipokrata" },
          { value: "Juglas", label: "Juglas" },
          { value: "Kaivas", label: "Kaivas" },
          { value: "Liduma", label: "Liduma" },
          { value: "Malienas", label: "Malienas" },
          { value: "Mezhciema", label: "Mezhciema" },
          { value: "Palsas", label: "Palsas" },
          { value: "Sedas", label: "Sedas" },
          { value: "Shmerla", label: "Shmerla" },
          { value: "Veca Bikernieku", label: "Veca Bikernieku" },
        ],
      },
      {
        value: "ozolciems",
        label: "Ozolciems",
        streets: [
          { value: "Abolu", label: "Abolu" },
          { value: "Barbeles", label: "Barbeles" },
          { value: "Basu", label: "Basu" },
          { value: "Batas", label: "Batas" },
          { value: "Berzlapu", label: "Berzlapu" },
          { value: "Beverinas", label: "Beverinas" },
          { value: "Bikstu", label: "Bikstu" },
          { value: "Blidenes", label: "Blidenes" },
          { value: "Bringu", label: "Bringu" },
          { value: "Brucenes", label: "Brucenes" },
          { value: "Bruknas", label: "Bruknas" },
          { value: "Chakstes g.", label: "Chakstes g." },
          { value: "Dika", label: "Dika" },
          { value: "Graudu", label: "Graudu" },
          { value: "Irbenes", label: "Irbenes" },
          { value: "Kaplavas", label: "Kaplavas" },
          { value: "Kartupelu", label: "Kartupelu" },
          { value: "Liesmas", label: "Liesmas" },
          { value: "Livciema", label: "Livciema" },
          { value: "Malu", label: "Malu" },
          { value: "Mezhkalna", label: "Mezhkalna" },
          { value: "Misas", label: "Misas" },
          { value: "Ozolciema", label: "Ozolciema" },
          { value: "Pipenu", label: "Pipenu" },
          { value: "Priedkalna", label: "Priedkalna" },
          { value: "Ritausmas", label: "Ritausmas" },
          { value: "Saulkalnes", label: "Saulkalnes" },
          { value: "Serenes", label: "Serenes" },
          { value: "Spulgas", label: "Spulgas" },
          { value: "Tervetes", label: "Tervetes" },
          { value: "Tiraines", label: "Tiraines" },
          { value: "Tumes", label: "Tumes" },
          { value: "Valdeku", label: "Valdeku" },
          { value: "Vienibas g.", label: "Vienibas g." },
          { value: "Zalenieku", label: "Zalenieku" },
        ],
      },
      {
        value: "plyavnieki",
        label: "Plyavnieki",
        streets: [
          { value: "Deglava", label: "Deglava" },
          { value: "Dravnieku", label: "Dravnieku" },
          { value: "Dzenu", label: "Dzenu" },
          { value: "Grestes", label: "Grestes" },
          { value: "Ilukstes", label: "Ilukstes" },
          { value: "J. Vaciesha", label: "J. Vaciesha" },
          { value: "Janavartu", label: "Janavartu" },
          { value: "Jasmuizhas", label: "Jasmuizhas" },
          { value: "Katlakalna", label: "Katlakalna" },
          { value: "Kaudzisu", label: "Kaudzisu" },
          { value: "Krustpils", label: "Krustpils" },
          { value: "Kupricu", label: "Kupricu" },
          { value: "Lubanas", label: "Lubanas" },
          { value: "Pavasara g.", label: "Pavasara g." },
          { value: "Plavnieku", label: "Plavnieku" },
          { value: "Praulienas", label: "Praulienas" },
          { value: "Rembates", label: "Rembates" },
          { value: "Rencenu", label: "Rencenu" },
          { value: "Rudens", label: "Rudens" },
          { value: "Saharova", label: "Saharova" },
          { value: "Salnas", label: "Salnas" },
          { value: "Sesku", label: "Sesku" },
          { value: "Slavu", label: "Slavu" },
          { value: "Straupes", label: "Straupes" },
          { value: "Tinuzhu", label: "Tinuzhu" },
          { value: "Ulbrokas", label: "Ulbrokas" },
          { value: "Zebiekstes", label: "Zebiekstes" },
          { value: "Zemes", label: "Zemes" },
          { value: "Zhiguru", label: "Zhiguru" },
        ],
      },
      {
        value: "purvciems",
        label: "Purvciems",
        streets: [
          { value: "Ainavas", label: "Ainavas" },
          { value: "Andromedas g.", label: "Andromedas g." },
          { value: "Astras", label: "Astras" },
          { value: "Bikernieku", label: "Bikernieku" },
          { value: "Brantkalna", label: "Brantkalna" },
          { value: "Braslas", label: "Braslas" },
          { value: "Burtnieku", label: "Burtnieku" },
          { value: "Deglava", label: "Deglava" },
          { value: "Dudajeva g.", label: "Dudajeva g." },
          { value: "Dzelzavas", label: "Dzelzavas" },
          { value: "Ezhu", label: "Ezhu" },
          { value: "Fazanu", label: "Fazanu" },
          { value: "Ieriku", label: "Ieriku" },
          { value: "Ilukstes", label: "Ilukstes" },
          { value: "Indula", label: "Indula" },
          { value: "Kalsnavas", label: "Kalsnavas" },
          { value: "Kastranes", label: "Kastranes" },
          { value: "Keguma", label: "Keguma" },
          { value: "Klugu", label: "Klugu" },
          { value: "Kriku", label: "Kriku" },
          { value: "Kronu", label: "Kronu" },
          { value: "Kurmju", label: "Kurmju" },
          { value: "Laimdotas", label: "Laimdotas" },
          { value: "Lielvardes", label: "Lielvardes" },
          { value: "Madonas", label: "Madonas" },
          { value: "Marcienas", label: "Marcienas" },
          { value: "Marsa g.", label: "Marsa g." },
          { value: "Mednu", label: "Mednu" },
          { value: "Nicgales", label: "Nicgales" },
          { value: "Paipalu", label: "Paipalu" },
          { value: "Pilu", label: "Pilu" },
          { value: "Puces", label: "Puces" },
          { value: "Purvciema", label: "Purvciema" },
          { value: "Raunas", label: "Raunas" },
          { value: "Rubenu", label: "Rubenu" },
          { value: "Saktas", label: "Saktas" },
          { value: "Sesku", label: "Sesku" },
          { value: "Staiceles", label: "Staiceles" },
          { value: "Starku", label: "Starku" },
          { value: "Stirnu", label: "Stirnu" },
          { value: "Sveku", label: "Sveku" },
          { value: "Teteru", label: "Teteru" },
          { value: "Unijas", label: "Unijas" },
          { value: "Upenu", label: "Upenu" },
          { value: "Vaidavas", label: "Vaidavas" },
          { value: "Vairoga", label: "Vairoga" },
          { value: "Varaviksnes g.", label: "Varaviksnes g." },
          { value: "Varzhu", label: "Varzhu" },
          { value: "Vejavas", label: "Vejavas" },
          { value: "Vesmas", label: "Vesmas" },
          { value: "Virshu", label: "Virshu" },
          { value: "Zhagatu", label: "Zhagatu" },
          { value: "Zilenu", label: "Zilenu" },
          { value: "Zvaigznaja g.", label: "Zvaigznaja g." },
        ],
      },
      {
        value: "rumbula",
        label: "Rumbula",
        streets: [
          { value: "Atbalss", label: "Atbalss" },
          { value: "Cuibes", label: "Cuibes" },
          { value: "Darzinu", label: "Darzinu" },
          { value: "Gaisa", label: "Gaisa" },
          { value: "Janogu", label: "Janogu" },
          { value: "Krustpils", label: "Krustpils" },
          { value: "Lidlauka", label: "Lidlauka" },
          { value: "Lokomotives", label: "Lokomotives" },
          { value: "Masinu", label: "Masinu" },
          { value: "Maskavas", label: "Maskavas" },
          { value: "Mazjumpravas", label: "Mazjumpravas" },
          { value: "Nulpes", label: "Nulpes" },
          { value: "Rumbas", label: "Rumbas" },
          { value: "Strengu", label: "Strengu" },
          { value: "Vejsturu", label: "Vejsturu" },
          { value: "Visku", label: "Visku" },
        ],
      },
      {
        value: "sarkandaugava",
        label: "Sarkandaugava",
        streets: [
          { value: "Aleksha", label: "Aleksha" },
          { value: "Allazhu", label: "Allazhu" },
          { value: "Aptiekas", label: "Aptiekas" },
          { value: "Azhu", label: "Azhu" },
          { value: "Bukultu", label: "Bukultu" },
          { value: "Celinieku", label: "Celinieku" },
          { value: "Dambja", label: "Dambja" },
          { value: "Duntes", label: "Duntes" },
          { value: "Eksporta", label: "Eksporta" },
          { value: "Ganibu d.", label: "Ganibu d." },
          { value: "Gaujienas", label: "Gaujienas" },
          { value: "Hanzas", label: "Hanzas" },
          { value: "Hapsalas", label: "Hapsalas" },
          { value: "Hirsa", label: "Hirsa" },
          { value: "Ilzenes", label: "Ilzenes" },
          { value: "Kadiku", label: "Kadiku" },
          { value: "Kakasekla d.", label: "Kakasekla d." },
          { value: "Laktas", label: "Laktas" },
          { value: "Lapstu", label: "Lapstu" },
          { value: "Limbazhu", label: "Limbazhu" },
          { value: "Lugazhu", label: "Lugazhu" },
          { value: "M. Vejzaksala", label: "M. Vejzaksala" },
          { value: "Ozolu", label: "Ozolu" },
          { value: "Patversmes", label: "Patversmes" },
          { value: "Pededzes", label: "Pededzes" },
          { value: "Priezhu", label: "Priezhu" },
          { value: "Pulkv. Briezha", label: "Pulkv. Briezha" },
          { value: "Ramulu", label: "Ramulu" },
          { value: "Rankas", label: "Rankas" },
          { value: "Saremas", label: "Saremas" },
          { value: "Sarkandaugavas", label: "Sarkandaugavas" },
          { value: "Simana", label: "Simana" },
          { value: "Skanstes", label: "Skanstes" },
          { value: "Sliezhu", label: "Sliezhu" },
          { value: "Sofijas", label: "Sofijas" },
          { value: "Sporta", label: "Sporta" },
          { value: "Tilta", label: "Tilta" },
          { value: "Trapenes", label: "Trapenes" },
          { value: "Tvaika", label: "Tvaika" },
          { value: "Uriekstes", label: "Uriekstes" },
          { value: "Vejzaksala", label: "Vejzaksala" },
          { value: "Viestura pr.", label: "Viestura pr." },
          { value: "Vitolu", label: "Vitolu" },
          { value: "Zageru", label: "Zageru" },
          { value: "Ziemelu", label: "Ziemelu" },
        ],
      },
      {
        value: "shampeteris-pleskodale",
        label: "Shampeteris-Pleskodale",
        streets: [
          { value: "Aizputes", label: "Aizputes" },
          { value: "Apuzes", label: "Apuzes" },
          { value: "Astras", label: "Astras" },
          { value: "Bartas", label: "Bartas" },
          { value: "Baseina", label: "Baseina" },
          { value: "Candera", label: "Candera" },
          { value: "Endzelina", label: "Endzelina" },
          { value: "Gramzdas", label: "Gramzdas" },
          { value: "Irlavas", label: "Irlavas" },
          { value: "Jaunmoku", label: "Jaunmoku" },
          { value: "Jaunpils", label: "Jaunpils" },
          { value: "Jurkalnes", label: "Jurkalnes" },
          { value: "Kalnciema", label: "Kalnciema" },
          { value: "Kauguru", label: "Kauguru" },
          { value: "Kemeru", label: "Kemeru" },
          { value: "Kruzes", label: "Kruzes" },
          { value: "Kukshu", label: "Kukshu" },
          { value: "Liegu", label: "Liegu" },
          { value: "Lielirbes", label: "Lielirbes" },
          { value: "Liepajas", label: "Liepajas" },
          { value: "M. Nometnu", label: "M. Nometnu" },
          { value: "Mazirbes", label: "Mazirbes" },
          { value: "Mikus", label: "Mikus" },
          { value: "Ozolnieku", label: "Ozolnieku" },
          { value: "Paltmales", label: "Paltmales" },
          { value: "Plenes", label: "Plenes" },
          { value: "Pleskodales", label: "Pleskodales" },
          { value: "Plesuma", label: "Plesuma" },
          { value: "Remtes", label: "Remtes" },
          { value: "Rucavas", label: "Rucavas" },
          { value: "Rudbarzhu", label: "Rudbarzhu" },
          { value: "Shampetera", label: "Shampetera" },
          { value: "Smardes", label: "Smardes" },
          { value: "Spares", label: "Spares" },
          { value: "Stendes", label: "Stendes" },
          { value: "Ulmana g.", label: "Ulmana g." },
          { value: "Valgales", label: "Valgales" },
          { value: "Ventas", label: "Ventas" },
          { value: "Ventspils", label: "Ventspils" },
          { value: "Volguntes", label: "Volguntes" },
          { value: "Zalves", label: "Zalves" },
          { value: "Zaru", label: "Zaru" },
          { value: "Zasulauka", label: "Zasulauka" },
          { value: "Zolitudes", label: "Zolitudes" },
        ],
      },
      {
        value: "shkirotava",
        label: "Shkirotava",
        streets: [
          { value: "Aknites", label: "Aknites" },
          { value: "Celteku", label: "Celteku" },
          { value: "Cesvaines", label: "Cesvaines" },
          { value: "Drujas", label: "Drujas" },
          { value: "Dzirkalu", label: "Dzirkalu" },
          { value: "Gara", label: "Gara" },
          { value: "Granita", label: "Granita" },
          { value: "Gravas", label: "Gravas" },
          { value: "Janavartu", label: "Janavartu" },
          { value: "Katlakalna", label: "Katlakalna" },
          { value: "Krustpils", label: "Krustpils" },
          { value: "Lubanas", label: "Lubanas" },
          { value: "Maleju", label: "Maleju" },
          { value: "Maza Rencenu", label: "Maza Rencenu" },
          { value: "Meiranu", label: "Meiranu" },
          { value: "Natru", label: "Natru" },
          { value: "Piedrujas", label: "Piedrujas" },
          { value: "Radzu", label: "Radzu" },
          { value: "Rajumsila", label: "Rajumsila" },
          { value: "Rencenu", label: "Rencenu" },
          { value: "Rubenkalna", label: "Rubenkalna" },
          { value: "Salnavas", label: "Salnavas" },
          { value: "Shkirotavas", label: "Shkirotavas" },
          { value: "Sila", label: "Sila" },
          { value: "Skembu", label: "Skembu" },
          { value: "Slavu", label: "Slavu" },
          { value: "Straupes", label: "Straupes" },
          { value: "Subates", label: "Subates" },
          { value: "Valles", label: "Valles" },
          { value: "Zasas", label: "Zasas" },
          { value: "Zhiguru", label: "Zhiguru" },
          { value: "Ziguru", label: "Ziguru" },
        ],
      },
      {
        value: "stacija-tirgus",
        label: "Stacija-Tirgus",
        streets: [
          { value: "13. janvara", label: "13. janvara" },
          { value: "Abrenes", label: "Abrenes" },
          { value: "Akademijas pl.", label: "Akademijas pl." },
          { value: "Avotu", label: "Avotu" },
          { value: "Birznieka-Upisha", label: "Birznieka-Upisha" },
          { value: "Centraltirgus", label: "Centraltirgus" },
          { value: "Chaka", label: "Chaka" },
          { value: "Dagdas", label: "Dagdas" },
          { value: "Dzirnavu", label: "Dzirnavu" },
          { value: "Elijas", label: "Elijas" },
          { value: "Elizabetes", label: "Elizabetes" },
          { value: "Gaizina", label: "Gaizina" },
          { value: "Gogola", label: "Gogola" },
          { value: "Jezusbaznicas", label: "Jezusbaznicas" },
          { value: "Krasta", label: "Krasta" },
          { value: "Lachplesha", label: "Lachplesha" },
          { value: "M. Krasta", label: "M. Krasta" },
          { value: "Marijas", label: "Marijas" },
          { value: "Maskavas", label: "Maskavas" },
          { value: "Negu", label: "Negu" },
          { value: "Pragas", label: "Pragas" },
          { value: "Pupolu", label: "Pupolu" },
          { value: "Pushkina", label: "Pushkina" },
          { value: "Riepnieku", label: "Riepnieku" },
          { value: "Rusina", label: "Rusina" },
          { value: "Sadovnikova", label: "Sadovnikova" },
          { value: "Satekles", label: "Satekles" },
          { value: "Skrindu", label: "Skrindu" },
          { value: "Spikeru", label: "Spikeru" },
          { value: "Stacijas pl.", label: "Stacijas pl." },
          { value: "Strugu", label: "Strugu" },
          { value: "Timoteja", label: "Timoteja" },
          { value: "Turgeneva", label: "Turgeneva" },
          { value: "Visvalzha", label: "Visvalzha" },
        ],
      },
      {
        value: "teika",
        label: "Teika",
        streets: [
          { value: "Aizkraukles", label: "Aizkraukles" },
          { value: "Aizpriezhu", label: "Aizpriezhu" },
          { value: "Aizsila", label: "Aizsila" },
          { value: "Apes", label: "Apes" },
          { value: "Araishu", label: "Araishu" },
          { value: "Ausmas", label: "Ausmas" },
          { value: "Austrumu", label: "Austrumu" },
          { value: "Bajaru", label: "Bajaru" },
          { value: "Berzaunes", label: "Berzaunes" },
          { value: "Berzpils", label: "Berzpils" },
          { value: "Bikernieku", label: "Bikernieku" },
          { value: "Brivibas", label: "Brivibas" },
          { value: "Brivibas g.", label: "Brivibas g." },
          { value: "Burtnieku", label: "Burtnieku" },
          { value: "Caunes", label: "Caunes" },
          { value: "Dzerbenes", label: "Dzerbenes" },
          { value: "Dzhutas", label: "Dzhutas" },
          { value: "Garkalnes", label: "Garkalnes" },
          { value: "Ieriku", label: "Ieriku" },
          { value: "Kastranes", label: "Kastranes" },
          { value: "Kaukaza", label: "Kaukaza" },
          { value: "Keguma", label: "Keguma" },
          { value: "Klugu", label: "Klugu" },
          { value: "Krimuldas", label: "Krimuldas" },
          { value: "Krivu", label: "Krivu" },
          { value: "Kronu", label: "Kronu" },
          { value: "Krustabaznicas", label: "Krustabaznicas" },
          { value: "Kurshu", label: "Kurshu" },
          { value: "Kveles", label: "Kveles" },
          { value: "Laimdotas", label: "Laimdotas" },
          { value: "Launkalnes", label: "Launkalnes" },
          { value: "Ledmanes", label: "Ledmanes" },
          { value: "Lenka", label: "Lenka" },
          { value: "Lielvardes", label: "Lielvardes" },
          { value: "Linezera", label: "Linezera" },
          { value: "Lizuma", label: "Lizuma" },
          { value: "M. Caunes", label: "M. Caunes" },
          { value: "M. Ropazhu", label: "M. Ropazhu" },
          { value: "Maija", label: "Maija" },
          { value: "Malienas", label: "Malienas" },
          { value: "Markalnes", label: "Markalnes" },
          { value: "Murjanu", label: "Murjanu" },
          { value: "Pabazhu", label: "Pabazhu" },
          { value: "Palmu", label: "Palmu" },
          { value: "Palsas", label: "Palsas" },
          { value: "Pekshena", label: "Pekshena" },
          { value: "Piebalgas", label: "Piebalgas" },
          { value: "Pikola", label: "Pikola" },
          { value: "Pudika", label: "Pudika" },
          { value: "Raiskuma", label: "Raiskuma" },
          { value: "Raunas", label: "Raunas" },
          { value: "Remines", label: "Remines" },
          { value: "Ropazhu", label: "Ropazhu" },
          { value: "Shmerla", label: "Shmerla" },
          { value: "Stamerienas", label: "Stamerienas" },
          { value: "Starku", label: "Starku" },
          { value: "Starta", label: "Starta" },
          { value: "Struktoru", label: "Struktoru" },
          { value: "Strupa", label: "Strupa" },
          { value: "Sturisha", label: "Sturisha" },
          { value: "Talivalzha", label: "Talivalzha" },
          { value: "Tirzas", label: "Tirzas" },
          { value: "Trikatas", label: "Trikatas" },
          { value: "Turaidas", label: "Turaidas" },
          { value: "Unijas", label: "Unijas" },
          { value: "Vairoga", label: "Vairoga" },
          { value: "Vangazhu", label: "Vangazhu" },
          { value: "Vasku", label: "Vasku" },
          { value: "Vaveres", label: "Vaveres" },
          { value: "Vidrizhu", label: "Vidrizhu" },
          { value: "Vijciema", label: "Vijciema" },
          { value: "Zalisha", label: "Zalisha" },
          { value: "Zalkshu", label: "Zalkshu" },
          { value: "Zemgala gatve", label: "Zemgala gatve" },
          { value: "Zemgalu", label: "Zemgalu" },
          { value: "Zemitana pl.", label: "Zemitana pl." },
        ],
      },
      {
        value: "tornjakalns",
        label: "Tornjakalns",
        streets: [
          { value: "Adulienas", label: "Adulienas" },
          { value: "Akachu", label: "Akachu" },
          { value: "Akmenu", label: "Akmenu" },
          { value: "Altonavas", label: "Altonavas" },
          { value: "Apshu", label: "Apshu" },
          { value: "Armisteda", label: "Armisteda" },
          { value: "Atgazenes", label: "Atgazenes" },
          { value: "Bauskas", label: "Bauskas" },
          { value: "Berzupes", label: "Berzupes" },
          { value: "Biekensalas", label: "Biekensalas" },
          { value: "Bieshu", label: "Bieshu" },
          { value: "Brivzemnieka", label: "Brivzemnieka" },
          { value: "Bukas", label: "Bukas" },
          { value: "Buru", label: "Buru" },
          { value: "Cepla", label: "Cepla" },
          { value: "Dandales", label: "Dandales" },
          { value: "Daudzeses", label: "Daudzeses" },
          { value: "Delu", label: "Delu" },
          { value: "Dika", label: "Dika" },
          { value: "Dzelzcela", label: "Dzelzcela" },
          { value: "Garozes", label: "Garozes" },
          { value: "Gimnastikas", label: "Gimnastikas" },
          { value: "Gustava", label: "Gustava" },
          { value: "H. Celmiņa", label: "H. Celmiņa" },
          { value: "Ilmajas", label: "Ilmajas" },
          { value: "Indrika", label: "Indrika" },
          { value: "Irbenes", label: "Irbenes" },
          { value: "Jelgavas", label: "Jelgavas" },
          { value: "Kartupelu", label: "Kartupelu" },
          { value: "Kliveru", label: "Kliveru" },
          { value: "Koku", label: "Koku" },
          { value: "Konrada", label: "Konrada" },
          { value: "Kugu", label: "Kugu" },
          { value: "Laivu", label: "Laivu" },
          { value: "Ludvika", label: "Ludvika" },
          { value: "M. Cepla", label: "M. Cepla" },
          { value: "Malu", label: "Malu" },
          { value: "Mukusalas", label: "Mukusalas" },
          { value: "Neretas", label: "Neretas" },
          { value: "Olivu", label: "Olivu" },
          { value: "Pastendes", label: "Pastendes" },
          { value: "Perkones", label: "Perkones" },
          { value: "Ranka d.", label: "Ranka d." },
          { value: "Rendas", label: "Rendas" },
          { value: "Robezhu", label: "Robezhu" },
          { value: "Satiksmes", label: "Satiksmes" },
          { value: "Saulkalnes", label: "Saulkalnes" },
          { value: "Silenes", label: "Silenes" },
          { value: "Skaistkalnes", label: "Skaistkalnes" },
          { value: "Stalgenes", label: "Stalgenes" },
          { value: "Stava", label: "Stava" },
          { value: "Stelpes", label: "Stelpes" },
          { value: "Sunu", label: "Sunu" },
          { value: "Svetes", label: "Svetes" },
          { value: "Tadaiku", label: "Tadaiku" },
          { value: "Telts", label: "Telts" },
          { value: "Tipografijas", label: "Tipografijas" },
          { value: "Tornakalna", label: "Tornakalna" },
          { value: "Trijadibas", label: "Trijadibas" },
          { value: "Ulmana g.", label: "Ulmana g." },
          { value: "Uzvaras boul.", label: "Uzvaras boul." },
          { value: "Vadakstes", label: "Vadakstes" },
          { value: "Vainodes", label: "Vainodes" },
          { value: "Valdeku", label: "Valdeku" },
          { value: "Valguma", label: "Valguma" },
          { value: "Vanagu", label: "Vanagu" },
          { value: "Veja", label: "Veja" },
          { value: "Vienibas g.", label: "Vienibas g." },
          { value: "Viesites", label: "Viesites" },
          { value: "Vilkaines", label: "Vilkaines" },
        ],
      },
      {
        value: "trisciems",
        label: "Trisciems",
        streets: [
          { value: "Aka", label: "Aka" },
          { value: "Araju", label: "Araju" },
          { value: "Jaunciema g.", label: "Jaunciema g." },
          { value: "Kalmju", label: "Kalmju" },
          { value: "Lapsukalna", label: "Lapsukalna" },
          { value: "Lichu", label: "Lichu" },
          { value: "Niedru", label: "Niedru" },
          { value: "Rudzu", label: "Rudzu" },
          { value: "Senlejas", label: "Senlejas" },
          { value: "Trīsciema 1. līnija", label: "Trīsciema 1. līnija" },
          { value: "Trīsciema 2. līnija", label: "Trīsciema 2. līnija" },
          { value: "Trīsciema 3. līnija", label: "Trīsciema 3. līnija" },
          { value: "Trīsciema 4. līnija", label: "Trīsciema 4. līnija" },
          { value: "Trīsciema 5. līnija", label: "Trīsciema 5. līnija" },
          { value: "Trīsciema 6. līnija", label: "Trīsciema 6. līnija" },
          { value: "Trīsciema 7. līnija", label: "Trīsciema 7. līnija" },
          { value: "Trīsciema 8. līnija", label: "Trīsciema 8. līnija" },
          { value: "Trīsciema 9. līnija", label: "Trīsciema 9. līnija" },
          { value: "Vadu", label: "Vadu" },
          { value: "Varpu", label: "Varpu" },
        ],
      },
      {
        value: "vecaki",
        label: "Vecaki",
        streets: [
          { value: "Aizveja", label: "Aizveja" },
          { value: "Alantu", label: "Alantu" },
          { value: "Amu", label: "Amu" },
          { value: "Attekas", label: "Attekas" },
          { value: "Blazmas", label: "Blazmas" },
          { value: "Cinu", label: "Cinu" },
          { value: "Cirsmas", label: "Cirsmas" },
          { value: "Dunu", label: "Dunu" },
          { value: "Garciema", label: "Garciema" },
          { value: "Garshas", label: "Garshas" },
          { value: "Ipalas", label: "Ipalas" },
          { value: "Kapu pr.", label: "Kapu pr." },
          { value: "Kishu", label: "Kishu" },
          { value: "Kuduma", label: "Kuduma" },
          { value: "Liedaga", label: "Liedaga" },
          { value: "Mangalu pr.", label: "Mangalu pr." },
          { value: "Mazsalacas", label: "Mazsalacas" },
          { value: "Murdu", label: "Murdu" },
          { value: "Paceplisha", label: "Paceplisha" },
          { value: "Pakalnes", label: "Pakalnes" },
          { value: "Pludmales", label: "Pludmales" },
          { value: "Puikules", label: "Puikules" },
          { value: "Putu", label: "Putu" },
          { value: "Saulgriezhu", label: "Saulgriezhu" },
          { value: "Seklu", label: "Seklu" },
          { value: "Selgas", label: "Selgas" },
          { value: "Shalkones", label: "Shalkones" },
          { value: "Sigu", label: "Sigu" },
          { value: "Tilaudu", label: "Tilaudu" },
          { value: "Treilinu", label: "Treilinu" },
          { value: "Vabu", label: "Vabu" },
          { value: "Vasarnieku", label: "Vasarnieku" },
          { value: "Vecaku pr.", label: "Vecaku pr." },
          { value: "Vedzelu", label: "Vedzelu" },
          { value: "Velavu", label: "Velavu" },
          { value: "Vimbu", label: "Vimbu" },
          { value: "Ziedu", label: "Ziedu" },
          { value: "Zvejas", label: "Zvejas" },
        ],
      },
      {
        value: "vecdaugava",
        label: "Vecdaugava",
        streets: [
          { value: "Airu", label: "Airu" },
          { value: "Algu", label: "Algu" },
          { value: "Atlantijas", label: "Atlantijas" },
          { value: "Brizes", label: "Brizes" },
          { value: "Kilu", label: "Kilu" },
          { value: "Krusas", label: "Krusas" },
          { value: "Laivinieku", label: "Laivinieku" },
          { value: "Lietus", label: "Lietus" },
          { value: "Palejas", label: "Palejas" },
          { value: "Plekstes", label: "Plekstes" },
          { value: "Rudulu", label: "Rudulu" },
          { value: "Vecaku pr.", label: "Vecaku pr." },
          { value: "Vecdaugavas", label: "Vecdaugavas" },
          { value: "Viesulu", label: "Viesulu" },
          { value: "Zvejas", label: "Zvejas" },
        ],
      },
      {
        value: "vecmilgravis",
        label: "Vecmilgravis",
        streets: [
          { value: "Alingu", label: "Alingu" },
          { value: "Anitas", label: "Anitas" },
          { value: "Atlantijas", label: "Atlantijas" },
          { value: "Baltasbaznicas", label: "Baltasbaznicas" },
          { value: "Birztalu", label: "Birztalu" },
          { value: "Ciedru", label: "Ciedru" },
          { value: "Dombrovska", label: "Dombrovska" },
          { value: "Draudzibas", label: "Draudzibas" },
          { value: "Dukura", label: "Dukura" },
          { value: "Edzhina g.", label: "Edzhina g." },
          { value: "Emmas", label: "Emmas" },
          { value: "Gales", label: "Gales" },
          { value: "Gliemezhu", label: "Gliemezhu" },
          { value: "Jaunciema g.", label: "Jaunciema g." },
          { value: "Kalngales", label: "Kalngales" },
          { value: "Kavu", label: "Kavu" },
          { value: "Kreimenu", label: "Kreimenu" },
          { value: "Laivinieku", label: "Laivinieku" },
          { value: "Ledus", label: "Ledus" },
          { value: "Meldru", label: "Meldru" },
          { value: "Melidas", label: "Melidas" },
          { value: "Mernieku", label: "Mernieku" },
          { value: "Mildas", label: "Mildas" },
          { value: "Natalijas", label: "Natalijas" },
          { value: "Pauguru", label: "Pauguru" },
          { value: "Punduru", label: "Punduru" },
          { value: "Rinkas", label: "Rinkas" },
          { value: "Rinuzhu", label: "Rinuzhu" },
          { value: "Rupnicas", label: "Rupnicas" },
          { value: "Sarmas", label: "Sarmas" },
          { value: "Skuju", label: "Skuju" },
          { value: "Sniedzes", label: "Sniedzes" },
          { value: "Sniega", label: "Sniega" },
          { value: "Staru", label: "Staru" },
          { value: "Stiebru", label: "Stiebru" },
          { value: "Tauvas", label: "Tauvas" },
          { value: "Traktoru", label: "Traktoru" },
          { value: "Vanadzina g.", label: "Vanadzina g." },
          { value: "Vecaku pr.", label: "Vecaku pr." },
          { value: "Vecmilgravja", label: "Vecmilgravja" },
          { value: "Vecmilgravja 1. l.", label: "Vecmilgravja 1. l." },
          { value: "Vecmilgravja 2. l.", label: "Vecmilgravja 2. l." },
          { value: "Vecmilgravja 5. l.", label: "Vecmilgravja 5. l." },
          { value: "Vecmilgravja 6. l.", label: "Vecmilgravja 6. l." },
          { value: "Vijolishu", label: "Vijolishu" },
          { value: "Zandartu", label: "Zandartu" },
          { value: "Ziemelblazmas", label: "Ziemelblazmas" },
          { value: "Zivju", label: "Zivju" },
        ],
      },
      {
        value: "vecriga",
        label: "Vecriga",
        streets: [
          { value: "11. novembra bund", label: "11. novembra bund" },
          { value: "13. janvara", label: "13. janvara" },
          { value: "Akla", label: "Akla" },
          { value: "Alberta pl.", label: "Alberta pl." },
          { value: "Aldaru", label: "Aldaru" },
          { value: "Alksnaja", label: "Alksnaja" },
          { value: "Amatu", label: "Amatu" },
          { value: "Anglikanu", label: "Anglikanu" },
          { value: "Arsenala", label: "Arsenala" },
          { value: "Aspazijas boul.", label: "Aspazijas boul." },
          { value: "Atgriezhu", label: "Atgriezhu" },
          { value: "Audeju", label: "Audeju" },
          { value: "Barona", label: "Barona" },
          { value: "Biskapa gate", label: "Biskapa gate" },
          { value: "Brivibas", label: "Brivibas" },
          { value: "Citadeles", label: "Citadeles" },
          { value: "Daugavas", label: "Daugavas" },
          { value: "Doma pl.", label: "Doma pl." },
          { value: "Eksporta", label: "Eksporta" },
          { value: "Gleznotaju", label: "Gleznotaju" },
          { value: "Grecinieku", label: "Grecinieku" },
          { value: "Herdera pl.", label: "Herdera pl." },
          { value: "Jana", label: "Jana" },
          { value: "Jana seta", label: "Jana seta" },
          { value: "Jauniela", label: "Jauniela" },
          { value: "Jekaba", label: "Jekaba" },
          { value: "Jekaba pl.", label: "Jekaba pl." },
          { value: "Kaleju", label: "Kaleju" },
          { value: "Kalku", label: "Kalku" },
          { value: "Keninu", label: "Keninu" },
          { value: "Klostera", label: "Klostera" },
          { value: "Konventa seta", label: "Konventa seta" },
          { value: "Kramu", label: "Kramu" },
          { value: "Kronvalda boul.", label: "Kronvalda boul." },
          { value: "Kungu", label: "Kungu" },
          { value: "Laipu", label: "Laipu" },
          { value: "Livu pl.", label: "Livu pl." },
          { value: "M. Jaunavu", label: "M. Jaunavu" },
          { value: "M. Jauniela", label: "M. Jauniela" },
          { value: "M. Miesnieku", label: "M. Miesnieku" },
          { value: "M. Monetu", label: "M. Monetu" },
          { value: "M. Muzeja", label: "M. Muzeja" },
          { value: "M. Peitavas", label: "M. Peitavas" },
          { value: "M. Pils", label: "M. Pils" },
          { value: "M. Skolas", label: "M. Skolas" },
          { value: "M. Smilshu", label: "M. Smilshu" },
          { value: "M. Trokshnu", label: "M. Trokshnu" },
          { value: "Maisa", label: "Maisa" },
          { value: "Marstalu", label: "Marstalu" },
          { value: "Meierovics boul.", label: "Meierovics boul." },
          { value: "Meistaru", label: "Meistaru" },
          { value: "Miesnieku", label: "Miesnieku" },
          { value: "Mikela", label: "Mikela" },
          { value: "Minsterejas", label: "Minsterejas" },
          { value: "Mucenieku", label: "Mucenieku" },
          { value: "Muitas", label: "Muitas" },
          { value: "Muku", label: "Muku" },
          { value: "Noliktavas", label: "Noliktavas" },
          { value: "Palasta", label: "Palasta" },
          { value: "Pasta", label: "Pasta" },
          { value: "Peitavas", label: "Peitavas" },
          { value: "Peldu", label: "Peldu" },
          { value: "Peterbaznicas", label: "Peterbaznicas" },
          { value: "Pils", label: "Pils" },
          { value: "Pils pl.", label: "Pils pl." },
          { value: "Polu gate", label: "Polu gate" },
          { value: "Pragas", label: "Pragas" },
          { value: "Radio", label: "Radio" },
          { value: "Ratslaukums", label: "Ratslaukums" },
          { value: "Republikas pl.", label: "Republikas pl." },
          { value: "Ridzenes", label: "Ridzenes" },
          { value: "Rozena", label: "Rozena" },
          { value: "Shkunu", label: "Shkunu" },
          { value: "Skarnu", label: "Skarnu" },
          { value: "Smilshu", label: "Smilshu" },
          { value: "Stralnieku pl.", label: "Stralnieku pl." },
          { value: "Svaru", label: "Svaru" },
          { value: "Teatra", label: "Teatra" },
          { value: "Tirgonu", label: "Tirgonu" },
          { value: "Torna", label: "Torna" },
          { value: "Trokshnu", label: "Trokshnu" },
          { value: "Udensvada", label: "Udensvada" },
          { value: "Vagnera", label: "Vagnera" },
          { value: "Valdemara", label: "Valdemara" },
          { value: "Valnu", label: "Valnu" },
          { value: "Vecpilsetas", label: "Vecpilsetas" },
          { value: "Zirgu", label: "Zirgu" },
        ],
      },
      {
        value: "voleri",
        label: "Voleri",
        streets: [
          { value: "Daugavgrivas h.", label: "Daugavgrivas h." },
          { value: "Kremeri", label: "Kremeri" },
          { value: "Podraga", label: "Podraga" },
          { value: "Voleru", label: "Voleru" },
        ],
      },
      {
        value: "yugla",
        label: "Yugla",
        streets: [
          { value: "Ainazhu", label: "Ainazhu" },
          { value: "Apogu", label: "Apogu" },
          { value: "Auduma", label: "Auduma" },
          { value: "Bakuzhu", label: "Bakuzhu" },
          { value: "Baltezera", label: "Baltezera" },
          { value: "Brivibas g.", label: "Brivibas g." },
          { value: "Ciemupes", label: "Ciemupes" },
          { value: "Dzirnupes", label: "Dzirnupes" },
          { value: "Forelu", label: "Forelu" },
          { value: "Garkalnes", label: "Garkalnes" },
          { value: "Inesha", label: "Inesha" },
          { value: "Juglas", label: "Juglas" },
          { value: "Juglas bund", label: "Juglas bund" },
          { value: "Karusu", label: "Karusu" },
          { value: "Krosa", label: "Krosa" },
          { value: "Kveles", label: "Kveles" },
          { value: "Liepkalna", label: "Liepkalna" },
          { value: "Lilastes", label: "Lilastes" },
          { value: "Lizuma", label: "Lizuma" },
          { value: "Lorupes", label: "Lorupes" },
          { value: "M. Juglas", label: "M. Juglas" },
          { value: "Makshkernieku", label: "Makshkernieku" },
          { value: "Malienas", label: "Malienas" },
          { value: "Markalnes", label: "Markalnes" },
          { value: "Meiju", label: "Meiju" },
          { value: "Murjanu", label: "Murjanu" },
          { value: "Pakalnieshu", label: "Pakalnieshu" },
          { value: "Pales", label: "Pales" },
          { value: "Palsas", label: "Palsas" },
          { value: "Pludinu", label: "Pludinu" },
          { value: "Rivas", label: "Rivas" },
          { value: "Salamandras", label: "Salamandras" },
          { value: "Samu", label: "Samu" },
          { value: "Shmerla", label: "Shmerla" },
          { value: "Silciema", label: "Silciema" },
          { value: "Skrudalienas", label: "Skrudalienas" },
          { value: "Slepotaju", label: "Slepotaju" },
          { value: "Smilshkalnu", label: "Smilshkalnu" },
          { value: "Susejas", label: "Susejas" },
          { value: "Tebras", label: "Tebras" },
          { value: "Tirzas", label: "Tirzas" },
          { value: "Tramplina", label: "Tramplina" },
          { value: "Tujas", label: "Tujas" },
          { value: "Udelu", label: "Udelu" },
          { value: "Umurgas", label: "Umurgas" },
          { value: "Vangazhu", label: "Vangazhu" },
          { value: "Veldres", label: "Veldres" },
          { value: "Vidzemes al.", label: "Vidzemes al." },
        ],
      },
      {
        value: "zakusala",
        label: "Zakusala",
        streets: [{ value: "Zakusalas bund", label: "Zakusalas bund" }],
      },
      {
        value: "zasulauks",
        label: "Zasulauks",
        streets: [
          { value: "Agenskalna", label: "Agenskalna" },
          { value: "Alises", label: "Alises" },
          { value: "Astras", label: "Astras" },
          { value: "Baldones", label: "Baldones" },
          { value: "Balozhu", label: "Balozhu" },
          { value: "Bartas", label: "Bartas" },
          { value: "Bezdeligu", label: "Bezdeligu" },
          { value: "Darba", label: "Darba" },
          { value: "Daugavgrivas", label: "Daugavgrivas" },
          { value: "Dreilinu", label: "Dreilinu" },
          { value: "Dzirciema", label: "Dzirciema" },
          { value: "Elviras", label: "Elviras" },
          { value: "Gregora", label: "Gregora" },
          { value: "Griezes", label: "Griezes" },
          { value: "Hamana", label: "Hamana" },
          { value: "Jaunmoku", label: "Jaunmoku" },
          { value: "Jaunpils", label: "Jaunpils" },
          { value: "Jurmalas g.", label: "Jurmalas g." },
          { value: "Kalnciema", label: "Kalnciema" },
          { value: "Kandavas", label: "Kandavas" },
          { value: "Kauguru", label: "Kauguru" },
          { value: "Klingeru", label: "Klingeru" },
          { value: "Kreslas", label: "Kreslas" },
          { value: "Kristapa", label: "Kristapa" },
          { value: "Kruzes", label: "Kruzes" },
          { value: "Kudras", label: "Kudras" },
          { value: "Kuldigas", label: "Kuldigas" },
          { value: "Kurpnieku", label: "Kurpnieku" },
          { value: "Lielirbes", label: "Lielirbes" },
          { value: "Liepajas", label: "Liepajas" },
          { value: "M. Bezdeligu", label: "M. Bezdeligu" },
          { value: "M. Kuldigas", label: "M. Kuldigas" },
          { value: "M. Nometnu", label: "M. Nometnu" },
          { value: "Margrietas", label: "Margrietas" },
          { value: "Martina", label: "Martina" },
          { value: "Mazirbes", label: "Mazirbes" },
          { value: "Melnsila", label: "Melnsila" },
          { value: "Ozolnieku", label: "Ozolnieku" },
          { value: "Paltmales", label: "Paltmales" },
          { value: "Plesuma", label: "Plesuma" },
          { value: "Remtes", label: "Remtes" },
          { value: "Rododendru", label: "Rododendru" },
          { value: "Rudbarzhu", label: "Rudbarzhu" },
          { value: "Slokas", label: "Slokas" },
          { value: "Spares", label: "Spares" },
          { value: "Tapeshu", label: "Tapeshu" },
          { value: "Ulmana g.", label: "Ulmana g." },
          { value: "Valdemara", label: "Valdemara" },
          { value: "Valentina", label: "Valentina" },
          { value: "Veca Jurmalas g.", label: "Veca Jurmalas g." },
          { value: "Ventas", label: "Ventas" },
          { value: "Ventspils", label: "Ventspils" },
          { value: "Vilipa", label: "Vilipa" },
        ],
      },
      {
        value: "ziepniekkalns",
        label: "Ziepniekkalns",
        streets: [
          { value: "Abolu", label: "Abolu" },
          { value: "Antenas", label: "Antenas" },
          { value: "Asteres", label: "Asteres" },
          { value: "Barbeles", label: "Barbeles" },
          { value: "Basu", label: "Basu" },
          { value: "Batas", label: "Batas" },
          { value: "Bauskas", label: "Bauskas" },
          { value: "Berzlapu", label: "Berzlapu" },
          { value: "Berzupes", label: "Berzupes" },
          { value: "Beverinas", label: "Beverinas" },
          { value: "Bikstu", label: "Bikstu" },
          { value: "Blidenes", label: "Blidenes" },
          { value: "Bringu", label: "Bringu" },
          { value: "Brucenes", label: "Brucenes" },
          { value: "Bruknas", label: "Bruknas" },
          { value: "Bukas", label: "Bukas" },
          { value: "Ceraukstes", label: "Ceraukstes" },
          { value: "Chakstes g.", label: "Chakstes g." },
          { value: "Dandales", label: "Dandales" },
          { value: "Dignajas", label: "Dignajas" },
          { value: "Dika", label: "Dika" },
          { value: "Dizozolu", label: "Dizozolu" },
          { value: "Dzhukstes", label: "Dzhukstes" },
          { value: "Ebelmuizas", label: "Ebelmuizas" },
          { value: "Garozes", label: "Garozes" },
          { value: "Gimnastikas", label: "Gimnastikas" },
          { value: "Graudu", label: "Graudu" },
          { value: "Ilmajas", label: "Ilmajas" },
          { value: "Irbenes", label: "Irbenes" },
          { value: "Islices", label: "Islices" },
          { value: "Kaletu", label: "Kaletu" },
          { value: "Kalu", label: "Kalu" },
          { value: "Kaplavas", label: "Kaplavas" },
          { value: "Kartupelu", label: "Kartupelu" },
          { value: "Kazdangas", label: "Kazdangas" },
          { value: "Kekavas", label: "Kekavas" },
          { value: "Kometas", label: "Kometas" },
          { value: "Liesmas", label: "Liesmas" },
          { value: "Livciema", label: "Livciema" },
          { value: "M. Sterstu", label: "M. Sterstu" },
          { value: "Malu", label: "Malu" },
          { value: "Medzes", label: "Medzes" },
          { value: "Mezhkalna", label: "Mezhkalna" },
          { value: "Mezkalna", label: "Mezkalna" },
          { value: "Misas", label: "Misas" },
          { value: "Mukusalas", label: "Mukusalas" },
          { value: "Nelku", label: "Nelku" },
          { value: "Ozolciema", label: "Ozolciema" },
          { value: "Padures", label: "Padures" },
          { value: "Pastendes", label: "Pastendes" },
          { value: "Perkones", label: "Perkones" },
          { value: "Pipenu", label: "Pipenu" },
          { value: "Priedkalna", label: "Priedkalna" },
          { value: "Priekules", label: "Priekules" },
          { value: "Putnu", label: "Putnu" },
          { value: "Racenu", label: "Racenu" },
          { value: "Rendas", label: "Rendas" },
          { value: "Ritausmas", label: "Ritausmas" },
          { value: "Sapņu", label: "Sapņu" },
          { value: "Saulkalnes", label: "Saulkalnes" },
          { value: "Serenes", label: "Serenes" },
          { value: "Silenes", label: "Silenes" },
          { value: "Siltuma", label: "Siltuma" },
          { value: "Sipolu", label: "Sipolu" },
          { value: "Skaistkalnes", label: "Skaistkalnes" },
          { value: "Sparnu", label: "Sparnu" },
          { value: "Spulgas", label: "Spulgas" },
          { value: "Staburaga", label: "Staburaga" },
          { value: "Stalgenes", label: "Stalgenes" },
          { value: "Stelpes", label: "Stelpes" },
          { value: "Sterstu", label: "Sterstu" },
          { value: "Svetes", label: "Svetes" },
          { value: "Svilpes", label: "Svilpes" },
          { value: "Tadaiku", label: "Tadaiku" },
          { value: "Tadaku", label: "Tadaku" },
          { value: "Tervetes", label: "Tervetes" },
          { value: "Tiraines", label: "Tiraines" },
          { value: "Tiruma", label: "Tiruma" },
          { value: "Tumes", label: "Tumes" },
          { value: "Vadakstes", label: "Vadakstes" },
          { value: "Vadaktes", label: "Vadaktes" },
          { value: "Vainodes", label: "Vainodes" },
          { value: "Valdeku", label: "Valdeku" },
          { value: "Vienibas g.", label: "Vienibas g." },
          { value: "Viesites", label: "Viesites" },
          { value: "Zalenieku", label: "Zalenieku" },
          { value: "Zalites", label: "Zalites" },
          { value: "Ziepju", label: "Ziepju" },
          { value: "Ziepniekkalna", label: "Ziepniekkalna" },
        ],
      },
      {
        value: "zolitude",
        label: "Zolitude",
        streets: [
          { value: "Anninmuizhas", label: "Anninmuizhas" },
          { value: "Apuzes", label: "Apuzes" },
          { value: "Augshzemes", label: "Augshzemes" },
          { value: "Biezina", label: "Biezina" },
          { value: "Chuguna", label: "Chuguna" },
          { value: "Endzelina", label: "Endzelina" },
          { value: "Gaviezes", label: "Gaviezes" },
          { value: "Gramzdas", label: "Gramzdas" },
          { value: "Grenchu", label: "Grenchu" },
          { value: "Imantas 12. l.", label: "Imantas 12. l." },
          { value: "Imantas 13. l.", label: "Imantas 13. l." },
          { value: "Imantas 15. l.", label: "Imantas 15. l." },
          { value: "Imantas 16. l.", label: "Imantas 16. l." },
          { value: "Imantas 17. l.", label: "Imantas 17. l." },
          { value: "Imantas 18. l.", label: "Imantas 18. l." },
          { value: "Irlavas", label: "Irlavas" },
          { value: "Jurkalnes", label: "Jurkalnes" },
          { value: "Kaltuves", label: "Kaltuves" },
          { value: "Karpu", label: "Karpu" },
          { value: "Labraga", label: "Labraga" },
          { value: "Lejina", label: "Lejina" },
          { value: "Liedes", label: "Liedes" },
          { value: "Lipaiku", label: "Lipaiku" },
          { value: "Mezhmalas", label: "Mezhmalas" },
          { value: "Mukupurva", label: "Mukupurva" },
          { value: "Naudites", label: "Naudites" },
          { value: "Nikraces", label: "Nikraces" },
          { value: "Platones", label: "Platones" },
          { value: "Plumju", label: "Plumju" },
          { value: "Priedaines", label: "Priedaines" },
          { value: "Raushu", label: "Raushu" },
          { value: "Rikshotaju", label: "Rikshotaju" },
          { value: "Rostokas", label: "Rostokas" },
          { value: "Ruses", label: "Ruses" },
          { value: "Salgales", label: "Salgales" },
          { value: "Sapala", label: "Sapala" },
          { value: "Sieksates", label: "Sieksates" },
          { value: "Skrines", label: "Skrines" },
          { value: "Ulmales", label: "Ulmales" },
          { value: "Ulmana g.", label: "Ulmana g." },
          { value: "Valgales", label: "Valgales" },
          { value: "Valtaiku", label: "Valtaiku" },
          { value: "Vecaines", label: "Vecaines" },
          { value: "Zantes", label: "Zantes" },
          { value: "Zebrenes", label: "Zebrenes" },
          { value: "Zolitudes", label: "Zolitudes" },
        ],
      },
      {
        value: "vef",
        label: "VEF",
        streets: [
          { value: "Berzaunes", label: "Berzaunes" },
          { value: "Bikernieku", label: "Bikernieku" },
          { value: "Brivibas", label: "Brivibas" },
          { value: "Brivibas g.", label: "Brivibas g." },
          { value: "Burtnieku", label: "Burtnieku" },
          { value: "Ieriku", label: "Ieriku" },
          { value: "Kastranes", label: "Kastranes" },
          { value: "Keguma", label: "Keguma" },
          { value: "Klugu", label: "Klugu" },
          { value: "Krimuldas", label: "Krimuldas" },
          { value: "Kronu", label: "Kronu" },
          { value: "Laimdotas", label: "Laimdotas" },
          { value: "Ledmanes", label: "Ledmanes" },
          { value: "Lielvardes", label: "Lielvardes" },
          { value: "Raunas", label: "Raunas" },
          { value: "Ropazhu", label: "Ropazhu" },
          { value: "Starku", label: "Starku" },
          { value: "Starta", label: "Starta" },
          { value: "Trikatas", label: "Trikatas" },
          { value: "Turaidas", label: "Turaidas" },
          { value: "Unijas", label: "Unijas" },
          { value: "Vairoga", label: "Vairoga" },
        ],
      },
      { value: "other", label: "Other" },
    ],
  },
  {
    value: "jurmala",
    label: "Jurmala",
    cities: [
      { value: "Asari", label: "Asari" },
      { value: "Bulduri", label: "Bulduri" },
      { value: "Buļļuciems", label: "Buļļuciems" },
      { value: "Dubulti", label: "Dubulti" },
      { value: "Dzintari", label: "Dzintari" },
      { value: "Jaundubulti", label: "Jaundubulti" },
      { value: "Jaunķemeri", label: "Jaunķemeri" },
      { value: "Kauguri", label: "Kauguri" },
      { value: "Ķemeri", label: "Ķemeri" },
      { value: "Lielupe", label: "Lielupe" },
      { value: "Majori", label: "Majori" },
      { value: "Melluži", label: "Melluži" },
      { value: "Priedaine", label: "Priedaine" },
      { value: "Pumpuri", label: "Pumpuri" },
      { value: "Sloka", label: "Sloka" },
      { value: "Vaivari", label: "Vaivari" },
      { value: "Valteri", label: "Valteri" },
      { value: "Other", label: "Other" },
    ],
  },
  {
    value: "riga-region",
    label: "Riga region",
    cities: [
      { value: "Baldone", label: "Baldone" },
      { value: "Baloži", label: "Baloži" },
      { value: "Olaine", label: "Olaine" },
      { value: "Salaspils", label: "Salaspils" },
      { value: "Saulkrasti", label: "Saulkrasti" },
      { value: "Sigulda", label: "Sigulda" },
      { value: "Vangaži", label: "Vangaži" },
      { value: "Ādažu nov.", label: "Ādažu nov." },
      { value: "Allažu pag.", label: "Allažu pag." },
      { value: "Babītes pag.", label: "Babītes pag." },
      { value: "Baldones l. t.", label: "Baldones l. t." },
      { value: "Carnikavas nov.", label: "Carnikavas nov." },
      { value: "Daugmales pag.", label: "Daugmales pag." },
      { value: "Garkalnes nov.", label: "Garkalnes nov." },
      { value: "Inčukalna nov.", label: "Inčukalna nov." },
      { value: "Ķekavas pag.", label: "Ķekavas pag." },
      { value: "Krimuldas pag.", label: "Krimuldas pag." },
      { value: "Mālpils pag.", label: "Mālpils pag." },
      { value: "Mārupes pag.", label: "Mārupes pag." },
      { value: "Mores pag.", label: "Mores pag." },
      { value: "Olaines pag.", label: "Olaines pag." },
      { value: "Ropažu nov.", label: "Ropažu nov." },
      { value: "Salas pag.", label: "Salas pag." },
      { value: "Salaspils l. t.", label: "Salaspils l. t." },
      { value: "Saulkrastu l. t.", label: "Saulkrastu l. t." },
      { value: "Sējas nov.", label: "Sējas nov." },
      { value: "Siguldas pag.", label: "Siguldas pag." },
      { value: "Stopiņu nov.", label: "Stopiņu nov." },
      { value: "Other", label: "Other" },
    ],
  },
];

export const FLATS_SERIES_OPTIONS = [
  { value: "103-th series", label: "103-th series" },
  { value: "104-th series", label: "104-th series" },
  { value: "119-th series", label: "119-th series" },
  { value: "467-th series", label: "467-th series" },
  { value: "602-th series", label: "602-th series" },
  { value: "Chruschev project", label: "Chruschev project" },
  { value: "Czech project", label: "Czech project" },
  { value: "Lithuanian project", label: "Lithuanian project" },
  { value: "Pre-war house", label: "Pre-war house" },
  { value: "Private house", label: "Private house" },
  { value: "Reconstructed house", label: "Reconstructed house" },
  { value: "Project for small familes", label: "Project for small familes" },
  { value: "Spec. project", label: "Spec. project" },
  { value: "Stalin project", label: "Stalin project" },
  { value: "New building", label: "New building" },
];

export const FLATS_HOUSE_TYPE_OPTIONS = [
  { value: "Brick", label: "Brick" },
  { value: "Brick-panel", label: "Brick-panel" },
  { value: "Panel", label: "Panel" },
  { value: "Panel-brick", label: "Panel-brick" },
  { value: "Stone", label: "Stone" },
  { value: "Wood", label: "Wood" },
];

export const showDistrict = (location) => location === "riga";

const locationWithCities = ["jurmala", "riga-region"];
export const showCities = (location) => locationWithCities.includes(location);
