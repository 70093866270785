<template>
  <div class="checkbox-container" @click="toggleCheckbox">
    <div
        :class="['checkbox', size, { checked: value }]"
    >
      <img height="14" width="14" v-if="value" :src="require('@/assets/images/checkboxTick.svg')" alt="&#10003;"/>
    </div>
    <p class="content-normal" v-if="label">{{ label }}</p>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'large',
      validator: value => ['large', 'small'].includes(value)
    },
    label: String
  },
  methods: {
    toggleCheckbox() {
      this.$emit('input', !this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/global_variables.scss";

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $grey500;
  border-radius: 4px;
}

.large {
  height: 24px;
  width: 24px;
}

.small {
  height: 16px;
  width: 16px;
}

.checked {
  background-color: $orange;
  border: 2px solid $orange;
  color: white;
}

.tick-mark {
  font-size: 16px;
}
</style>
