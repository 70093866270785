<template>
  <div v-if="marketplaces" class="add-edit-outputs-wrapper">
    <div v-for="(marketplace, index) in marketplaces.filter((mp) => !mp.hidden)" :key="marketplace.name" class="add-edit-outputs">
      <CustomCheckbox @input="(booleanValue) => marketplaceStatusUpdated(booleanValue, marketplace)"
                      v-model="marketplace.isActive" :label="marketplace.label || marketplace.name"/>
      <p style="white-space: nowrap"><b>{{ limits[marketplace.name].activeCount }}</b> / {{ limits[marketplace.name].exportLimit }}</p>
      <p v-if="index !== marketplaces.filter((mp) => !mp.hidden).length - 1">•</p>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from "@/components/CustomCheckbox";
import axios from "axios";

export default {
  name: "AddEditOutputs",
  components: {CustomCheckbox},
  props: ["listing"],
  data() {
    return {
      marketplaces: null,
      limits: null,
    }
  },
  async created() {
    await this.getSetupMarketplaces();
  },
  methods: {
    async getSetupMarketplaces() {
      const response = await axios.get(`/api/marketplaces/listing/${this.listing._id}`);
      this.marketplaces = response.data.marketplaces;
      this.limits = response.data.limits;
    },
    async marketplaceStatusUpdated(booleanValue, marketplace) {
      if (this.listing._id) {
        const response = await axios.post('/api/users/output-status-changed', {
          listing: {_id: this.listing._id},
          isActive: booleanValue,
          marketplace: marketplace
        })
        if (response && response.status === 200) {
          switch (response.data.message) {
            case "success":
              this.toastSuccess(this.$t("Updated"))
              await this.getSetupMarketplaces();
              break;
            case "marketplace_active_limit_exceeded":
              marketplace.isActive = false;
              this.toastError(`${marketplace.name} ${this.$t("LimitHasBeenReached")}`)
              break;
          }
        } else {
          marketplace.isActive = !booleanValue;
          this.toastError(this.$t("ErrorUpdating"))
        }
      }
    }
  }
}
</script>

<style scoped>

.add-edit-outputs-wrapper {
  width: 100%;
  display: flex;
  background-color: white;
  height: 48px;
  padding: 8px 16px;
  border-radius: 8px;
  gap: 12px;
}

.add-edit-outputs {
  display: flex;
  align-items: center;
  gap: 8px;
}

</style>