var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.marketplaces ? _c('div', {
    staticClass: "add-edit-outputs-wrapper"
  }, _vm._l(_vm.marketplaces.filter(function (mp) {
    return !mp.hidden;
  }), function (marketplace, index) {
    return _c('div', {
      key: marketplace.name,
      staticClass: "add-edit-outputs"
    }, [_c('CustomCheckbox', {
      attrs: {
        "label": marketplace.label || marketplace.name
      },
      on: {
        "input": function (booleanValue) {
          return _vm.marketplaceStatusUpdated(booleanValue, marketplace);
        }
      },
      model: {
        value: marketplace.isActive,
        callback: function ($$v) {
          _vm.$set(marketplace, "isActive", $$v);
        },
        expression: "marketplace.isActive"
      }
    }), _c('p', {
      staticStyle: {
        "white-space": "nowrap"
      }
    }, [_c('b', [_vm._v(_vm._s(_vm.limits[marketplace.name].activeCount))]), _vm._v(" / " + _vm._s(_vm.limits[marketplace.name].exportLimit))]), index !== _vm.marketplaces.filter(function (mp) {
      return !mp.hidden;
    }).length - 1 ? _c('p', [_vm._v("•")]) : _vm._e()], 1);
  }), 0) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }