var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkbox-container",
    on: {
      "click": _vm.toggleCheckbox
    }
  }, [_c('div', {
    class: ['checkbox', _vm.size, {
      checked: _vm.value
    }]
  }, [_vm.value ? _c('img', {
    attrs: {
      "height": "14",
      "width": "14",
      "src": require('@/assets/images/checkboxTick.svg'),
      "alt": "&#10003;"
    }
  }) : _vm._e()]), _vm.label ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }